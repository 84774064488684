/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout';
var componentsRouter = {
  path: '/components',
  component: Layout,
  redirect: 'noRedirect',
  name: 'ComponentDemo',
  meta: {
    title: 'components',
    icon: 'component'
  },
  children: [{
    path: 'tinymce',
    component: function component() {
      return import('@/views/components-demo/tinymce');
    },
    name: 'TinymceDemo',
    meta: {
      title: 'tinymce'
    }
  }, {
    path: 'markdown',
    component: function component() {
      return import('@/views/components-demo/markdown');
    },
    name: 'MarkdownDemo',
    meta: {
      title: 'markdown'
    }
  }, {
    path: 'json-editor',
    component: function component() {
      return import('@/views/components-demo/json-editor');
    },
    name: 'JsonEditorDemo',
    meta: {
      title: 'jsonEditor'
    }
  }, {
    path: 'split-pane',
    component: function component() {
      return import('@/views/components-demo/split-pane');
    },
    name: 'SplitpaneDemo',
    meta: {
      title: 'splitPane'
    }
  }, {
    path: 'avatar-upload',
    component: function component() {
      return import('@/views/components-demo/avatar-upload');
    },
    name: 'AvatarUploadDemo',
    meta: {
      title: 'avatarUpload'
    }
  }, {
    path: 'dropzone',
    component: function component() {
      return import('@/views/components-demo/dropzone');
    },
    name: 'DropzoneDemo',
    meta: {
      title: 'dropzone'
    }
  }, {
    path: 'sticky',
    component: function component() {
      return import('@/views/components-demo/sticky');
    },
    name: 'StickyDemo',
    meta: {
      title: 'sticky'
    }
  }, {
    path: 'count-to',
    component: function component() {
      return import('@/views/components-demo/count-to');
    },
    name: 'CountToDemo',
    meta: {
      title: 'countTo'
    }
  }, {
    path: 'mixin',
    component: function component() {
      return import('@/views/components-demo/mixin');
    },
    name: 'ComponentMixinDemo',
    meta: {
      title: 'componentMixin'
    }
  }, {
    path: 'back-to-top',
    component: function component() {
      return import('@/views/components-demo/back-to-top');
    },
    name: 'BackToTopDemo',
    meta: {
      title: 'backToTop'
    }
  }, {
    path: 'drag-dialog',
    component: function component() {
      return import('@/views/components-demo/drag-dialog');
    },
    name: 'DragDialogDemo',
    meta: {
      title: 'dragDialog'
    }
  }, {
    path: 'drag-select',
    component: function component() {
      return import('@/views/components-demo/drag-select');
    },
    name: 'DragSelectDemo',
    meta: {
      title: 'dragSelect'
    }
  }, {
    path: 'dnd-list',
    component: function component() {
      return import('@/views/components-demo/dnd-list');
    },
    name: 'DndListDemo',
    meta: {
      title: 'dndList'
    }
  }, {
    path: 'drag-kanban',
    component: function component() {
      return import('@/views/components-demo/drag-kanban');
    },
    name: 'DragKanbanDemo',
    meta: {
      title: 'dragKanban'
    }
  }]
};
export default componentsRouter;