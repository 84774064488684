import request from '@/utils/request';

// 能耗告警接口

// 获取所有告警内容
export function getAllDictionaryApi() {
  return request({
    url: '/ssdm/ssd-warn-dictionary/getAllDictionary',
    method: 'get'
  });
}

// 创建类型时层级展示事件类型，能耗类别，参数类型
export function getWarnDictionaryApi(data) {
  return request({
    url: '/ssdm/ssd-warn-dictionary/getWarnDictionary',
    method: 'post',
    data: data
  });
}

// 获取能耗码下表具树
export function getDeviceTreeByEnergyNumberApi(params) {
  return request({
    url: '/ssdm/ssd-warn-rule/getDeviceTreeByEnergyNumber',
    method: 'get',
    params: params
  });
}

// 分页查询告警规则
export function fuzzyQueryWarnRuleApi(data) {
  return request({
    url: '/ssdm/ssd-warn-rule/fuzzyQueryWarnRule',
    method: 'post',
    data: data
  });
}

// 变更启用状态
export function changeStateByIdApi(params) {
  return request({
    url: '/ssdm/ssd-warn-rule/changeStateById',
    method: 'get',
    params: params
  });
}

// 批量删除规则
export function deleteByIdListApi(data) {
  return request({
    url: '/ssdm/ssd-warn-rule/deleteByIdList',
    method: 'post',
    data: data
  });
}

// 创建规则
export function createWarnRuleApi(data) {
  return request({
    url: '/ssdm/ssd-warn-rule/createWarnRule',
    method: 'post',
    data: data
  });
}

// 编辑规则
export function editWarnRuleApi(data) {
  return request({
    url: '/ssdm/ssd-warn-rule/editWarnRule',
    method: 'post',
    data: data
  });
}

// 各个告警等级数量
export function getWarnLevelsCountApi() {
  return request({
    url: '/ssdm/ssd-warn-record/getWarnLevelsCount',
    method: 'get'
  });
}

// 分页查询告警记录
export function fuzzyQueryWarnRecordApi(data) {
  return request({
    url: '/ssdm/ssd-warn-record/fuzzyQueryWarnRecord',
    method: 'post',
    data: data
  });
}

// 各个告警等级数量
export function getRecordByIdApi(params) {
  return request({
    url: '/ssdm/ssd-warn-record/getRecordById',
    method: 'get',
    params: params
  });
}

// 各个告警等级数量
export function getWarnRecordDetailApi(data) {
  return request({
    url: '/ssdm/ssd-warn-record-detail/getWarnRecordDetailList',
    method: 'post',
    data: data
  });
}

// 更新静音状态
export function updateIsMuteApi(data) {
  return request({
    url: '/ssdm/ssd-warn-record/updateIsMute',
    method: 'post',
    data: data
  });
}
// 手动上报告警记录
export function uploadWarnRecordApi(data) {
  return request({
    url: '/ssdm/ssd-warn-record/uploadWarnRecord',
    method: 'post',
    data: data
  });
}

// 更新忽略状态
export function updateIsIgnoreApi(data) {
  return request({
    url: '/ssdm/ssd-warn-record/updateIsIgnore',
    method: 'post',
    data: data
  });
}

// 分页查询告警记录文件
export function fuzzyQueryFileApi(data) {
  return request({
    url: '/ssdm/ssd-warn-record-file/fuzzyQueryFile',
    method: 'post',
    data: data
  });
}

// 文件删除
export function deleteFileApi(params) {
  return request({
    url: '/ssdm/ssd-warn-record-file/deleteFile',
    method: 'get',
    params: params
  });
}

// 更新确认状态
export function updateIsConfirmApi(data) {
  return request({
    url: '/ssdm/ssd-warn-record/updateIsConfirm',
    method: 'post',
    data: data
  });
}

// 批量更新确认状态
export function updateIsConfirmByListApi(data) {
  return request({
    url: '/ssdm/ssd-warn-record/updateIsConfirmByList',
    method: 'post',
    data: data
  });
}

// 批量更新忽略状态
export function updateIsIgnoreByListApi(data) {
  return request({
    url: '/ssdm/ssd-warn-record/updateIsIgnoreByList',
    method: 'post',
    data: data
  });
}

// 批量更新静音状态
export function updateIsMuteByListApi(data) {
  return request({
    url: '/ssdm/ssd-warn-record/updateIsMuteByList',
    method: 'post',
    data: data
  });
}

// 获取当前报警状态
export function getWarnLineApi() {
  return request({
    url: '/ssdm/ssd-warn-line/getWarnLine',
    method: 'get'
  });
}

// 文件创建
export function uploadFileApi(data) {
  return request({
    url: '/ssdm/ssd-warn-record-file/uploadFile',
    method: 'post',
    data: data
  });
}

// 更具告警规则主键获取告警规则详细信息
export function getWarnRuleByIdApi(params) {
  return request({
    url: '/ssdm/ssd-warn-rule/getWarnRuleById',
    method: 'get',
    params: params
  });
}

// 更新当前报警状态
export function updateWarnLineApi(params) {
  return request({
    url: '/ssdm/ssd-warn-line/updateWarnLine',
    method: 'get',
    params: params
  });
}

// 委派工单
export function createWorkOrderApi(data) {
  return request({
    url: '/ssdm/ssd-warn-record/createWorkOrder',
    method: 'post',
    data: data
  });
}

// 委派工单
export function batchCreateWorkOrderApi(data) {
  return request({
    url: '/ssdm/ssd-warn-record/batchCreateWorkOrder',
    method: 'post',
    data: data
  });
}