export default {
  route: {
    dashboard: '首页',
    documentation: '文档',
    guide: '引导页',
    permission: '权限测试页',
    rolePermission: '角色权限',
    pagePermission: '页面权限',
    directivePermission: '指令权限',
    icons: '图标',
    orgManage: '机构管理',
    patrolTaskDetail: '巡检任务详情',
    applicationManage: '应用管理',
    handleTemplate: '模板配置',
    baseApp: '基础信息（新）',
    dataDic: '数据字典',
    updPwd: '修改密码',
    statisticalAnalysis: '统计分析',
    repairAnalysis: '工单统计',
    newRepairAnalysis: '工单统计（新）',
    wxManage: '微信管理',
    wxUser: '关注管理',
    wxOfficial: '公众号管理',
    mobileSensor: '传感器手机端',
    weeklyIndex: '周报',
    weeklyMonitor: '周报首页',
    weeklyWork: '周报详情',
    mobileRepair: '手机端报修',
    mobileFM: 'FM手机端',
    mobileFire: '消防手机端',
    mobileFireRepair: '维修指派',
    mobileSensorHome: '首页',
    mobileSensorMonitor: '物联监测',
    mobileTaskMonitor: '工单总览',
    mobileSensorMonitorList: '物联监测列表',
    mobileSensorDetail: '传感器详情',
    monitorSensorData: '数据信号',
    appService: '移动服务',
    messageCenter: '消息中心',
    noticeDetail: '公告详情',
    noticeManage: '公告管理',
    parkingVisitor: '预约监控',
    dbManage: '数据库维护',
    developer: '开发者选项',
    logFile: '日志查询',
    menusConfig: '菜单设置',
    spareManage: '备品管理',
    mobileSensorMessageSetting: '工单维修',
    systemConfig: '系统设置',
    roleConfig: '角色设置',
    activityManage: '流程管理',
    task: '任务',
    parkingMonitor: '车辆监控',
    parkingService: '停车服务',
    visit: '访客预约',
    visitConfig: '配置',
    buildingConfig: '楼宇配置',
    orgConfig: '公司配置',
    visitorRegister: '前台登记',
    visitorRegisterForXHQ: '前台登记',
    staffConfig: '人员配置',
    carInList: '入场记录',
    carOutList: '出场记录',
    history: '维修记录',
    stopService: '停车服务',
    stopPart: '停车厂',
    windowManage: '智慧窗户',
    windowsRealTime: '实时显示',
    authority: '权限管理',
    patternManage: '模式管理',
    mobileWindow: '智慧窗户移动端',
    windowList: '窗户列表',
    operateWindow: '窗控页面',
    newWindowPattern: '新增窗控模式',
    modeManagement: '模式管理',
    maintenanceManage: '维保管理',
    fireManageAdapt: '消防总览',
    nowFireData: '实时消防数据',
    fireDeployment: '消防设备部署',
    welinkFire: '消防',
    welinkWorkOrder: '工单中心',
    historyStore: '历史记录',
    cloudSalesControlCenter: '云消控中心',
    dataCenter: '数据中心',
    dataCenterBackUp: '消防报告',
    fireInTimeView: '消防实时显示',
    instruction: '使用说明',
    bindUser: '用户绑定',
    taskList: '任务列表',
    taskDetail: '任务详情',
    equipmentManage: '设备管理',
    equipmentDetail: '设备详情',
    fileMaintain: '文件管理',
    organization: '组织架构',
    company: '公司设置',
    department: '部门设置',
    userManage: '人员设置',
    repairManage: '维修管理',
    smartOperations: '智慧运维',
    baseManage: '基础信息管理',
    spaceManage: '空间管理',
    partitionSet: '分区设置',
    workManage: '工单中心',
    worksheet: '工单报修',
    reportForRepairs: '报事报修',
    repairRecord: '报事报修记录',
    workList: '我的工单',
    workDetail: '工单明细',
    dailyManage: '日常管理',
    PatrolManage: '巡检管理',
    newPatrolManage: '巡检管理(新)',
    roomPatrol: '房间巡检',
    roomSelectDate: '房间巡检',
    roomList: '房间列表',
    fireSystemDeployment: '消防系统部署',
    accountWarn: '公众号报警',
    HKManage: '保洁管理',
    sensor: '日常监控',
    monitorSystem: '监控系统部署',
    realTimeMonitor: '实时监控系统',
    alarmView: '警情查看',
    monitoringDataOverview: '监控数据总览',
    fireManagement: '消防管理',
    fireCloudManagement: '消防云管理',
    allFireDevice: '消防设备管理',
    equipmentSystem: '智慧运营系统字典表',
    components: '组件',
    tinymce: '富文本编辑器',
    markdown: 'Markdown',
    jsonEditor: 'JSON 编辑器',
    dndList: '列表拖拽',
    splitPane: 'Splitpane',
    avatarUpload: '头像上传',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'Count To',
    componentMixin: '小组件',
    backToTop: '返回顶部',
    dragDialog: '拖拽 Dialog',
    dragSelect: '拖拽 Select',
    dragKanban: '可拖拽看板',
    charts: '图表',
    keyboardChart: '键盘图表',
    lineChart: '折线图',
    mixChart: '混合图表',
    example: '综合实例',
    nested: '路由嵌套',
    menu1: '菜单1',
    'menu1-1': '菜单 1-1',
    'menu1-2': '菜单 1-2',
    'menu1-2-1': '菜单 1-2-1',
    'menu1-2-2': '菜单 1-2-2',
    'menu1-3': '菜单 1-3',
    menu2: '菜单 2',
    Table: 'Table',
    dynamicTable: '动态 Table',
    dragTable: '拖拽 Table',
    inlineEditTable: 'Table 内编辑',
    complexTable: '综合 Table',
    tab: 'Tab',
    form: '表单',
    createArticle: '创建文章',
    editArticle: '编辑文章',
    articleList: '文章列表',
    errorPages: '错误页面',
    page401: '401',
    page404: '404',
    errorLog: '错误日志',
    excel: 'Excel',
    exportExcel: '导出 Excel',
    selectExcel: '导出 已选择项',
    mergeHeader: '导出 多级表头',
    uploadExcel: '上传 Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: '换肤',
    clipboardDemo: 'Clipboard',
    i18n: '国际化',
    externalLink: '外链',
    profile: '个人中心',
    maintenanceManagement: '维修管理',
    maintenanceSkillsMatching: '故障技能匹配',
    maintenancePersonnelManagement: '维修人员管理',
    newSpaceManage: '新空间管理',
    activityGroupIndex: '流程组管理',
    wxuser: '微信公众号用户管理',
    code: '一码通',
    myCode: '我的一码通',
    codeFace: '人脸注册',
    baseData: '基础数据管理',
    personnelSettingIndex: '人员设置',
    codeTrace: '一码通追踪',
    visitorLog: '访客记录',
    inactiveList: '不活跃人员管理',
    attendanceManagement: '考勤管理',
    equipmentSetatusManagement: '设备状态管理',
    // 国贸一码通
    international: '国贸一码通',
    internationalFaceCode: '基础设备管理',
    internationalUserCode: '部门权限管理',
    internationalOrgCode: '人员管理',
    internationalDeviceCode: '一码通追踪',
    internationalCardCode: '设备状态管理',
    internationalTrack: '考勤管理',
    internationalFaceInfo: '不活跃人员管理',
    hotWater: '生活热水',
    domesticHotWater: '生活热水界面',
    energyConsumptionControl: '控制中心',
    refrigerationUnit: '冷机群控',
    heatPump: '热泵群控',
    refrigerationUnit1: '冷机群控（展示版）',
    heatPump1: '热泵群控（展示版）',
    monitoringCenter: '监测中心',
    coldMachine: '冷机运行详情',
    heatPumpDetail: '热泵运行详情',
    hotAndColdWaterPumpDetail: '冷热水泵运行详情',
    coolingWaterPumpDetail: '冷却水泵运行详情',
    chilledWaterPumpsDetail: '冷冻水泵运行详情',
    coolingTowersDetail: '冷却塔运行详情',
    airConditioningboxDetail: '空调箱运行详情',
    airConditioningboxDetail1: '空调箱运行详情1',
    operationMonitoringDetail: '运行监测详情',
    airConditioningbox: '空调箱群控',
    environmentalKanban: '环境看板',
    pointCustomization: '设备点位自定义',
    alarmManagement: '告警管理',
    firstScreen: '首界面',
    energyUseReport: '用能报告',
    algorithmModel: '算法模型',
    carefulPlanning: '开机优化',
    logicControl: '逻辑控制',
    alarmLinkage: '告警联动',
    linkageConfiguration: '联动配置',
    configurationDetails: '配置详情',
    // 展馆业务系统
    rectifyTheSystem: '整改系统',
    rectificationInformation: '整改录入',
    rectificationReviewer: '整改复核',
    powerDistributions: '配电情况',
    statistics: '数据统计',
    boothInformation: '展位信息',
    rectificationAndDistribution: '整改派发',
    peopleManagement: '人员管理',
    login: '登录',
    // 大华首页名称
    toDoListCompleted: '待办已办',
    serviceEvaluation: '服务评价',
    // 碳平台
    carbonAccounting: '碳核算',
    energyCarbonEmission: '能源碳排放',
    behavioralCarbonEmission: '行为碳排放',
    carbonReductionProjectAccounting: '减碳项目核算',
    // 工程安保移动端
    engineeringSecurity: '工程安保',
    menuBar: '菜单栏',
    rectificationAndAddition: '整改新增',
    newRectification: '新增整改',
    viewRectification: '查看整改',
    boothPowerDistribution: '展位配电',
    summarySheet: '汇总单',
    checkList: '复核列表',
    // 运营
    operation: '运营',
    exhibitionInformation: '展会信息',
    newExhibition: '新增展会',
    aggregateDistribution: '汇总派发',
    // 流量监测
    overallTraffic: '整体流量',
    groupTrafficOverview: '流量概览',
    groupFlow: '展会流量',
    group: '流量对比',
    analyse: '首页',
    passengerFlow: '客流量',
    passengerFlowMonitoring: '客流监测',
    smartLight: '智慧照明',
    smartLightMonitor: '终端控制',
    mobileSmartLight: '智慧照明手机端',
    mobileSmartLightIndex: '智慧照明手机端',
    mobileSmartLightMonitor: '终端控制',
    mobileSmartLightScenes: '模式管理',
    MobileSmartLightDeviceSetting: '设备设置',
    smartLightAuthority: '权限管理',
    dept: '部门权限管理',
    visitorIndex: '访客预约',
    wxTemManage: '模板管理',
    MobileVisitor: '访客预约手机端',
    MobileVisitorVistor: '访客预约页',
    MobileTenant: '邀请访客',
    MobileHistory: '查看历史',
    AppointmentDetails: '预约详细信息',
    workorder: '数据中心',
    inspect: '安全巡检',
    inspectedAddress: '受检地址管理',
    inspectionTask: '安检任务',
    laws: '法律法规',
    mobileInspect: '安全巡检手机端',
    inspectiIndex: '任务列表',
    powerManage: '报表导出',
    powerIndex: '公司能耗统计',
    repairHistory: '历史工单',
    createRectifyNotice: '创建整改通知单',
    taskDetails: '计划详情',
    outTaskList: '外部任务列表',
    outTaskDetails: '外部任务详情',
    rectificationNotice: '整改通知单',
    taskDetailOut: '整改通知单',
    rectificationReply: '整改回复单',
    viewDetails: '查看详情',
    rectificationReview: '整改复核单',
    signIns: '签到页',
    inspection: '临检',
    inspectHome: '安全巡检',
    maintenanceInformation: '维修信息管理',
    maintenancePersonnelType: '维修人员类型',
    cockpit: '领导驾驶舱',
    power: '能源管理',
    fgp: '峰谷平能耗统计',
    equipment: '设备监控',
    powerSystem: '远程抄表',
    overview: '远程抄表总览',
    powerOverview: '远程抄表总览',
    deriveExcl: '重点设备能耗统计',
    meterSync: '电表数据同步统计',
    aiCentre: 'AI中心',
    AICentre: 'AI中心',
    strategic: '策略启停',
    remoteReport: '能耗监测',
    projectManage: '研发管理',
    projectManageHome: '项目管理',
    projectDetail: '项目详情',
    security: '安防视频',
    securityMonitoringDataOverview: '安防设备总览',
    securityDeployment: '安防设备部署',
    securityRealTimeMonitoring: '安防实时视频',
    mobileSecurity: '安防手机端',
    mobileSecurityRepair: '维修指派',
    securityRepairForm: '安防工单',
    deviceManagement: '设备管理',
    threeLevel: '四级监督',
    powerToSee: '电力与设备',
    powerWatch: '能耗分析',
    conditioningSystem: '暖空空调系统',
    guestRoomManage: '客房管理',
    shareTrain: '共享培训',
    trainList: '培训列表',
    equipmentAnalysis: '设备分析',
    messageSending: '物联检测配置',
    equipmentDesDetails: '巡检设备详情页',
    equipmentDes: '设备列表',
    emptySystem: '空调系统',
    equipmenHhome: '日常巡检',
    equipmentList: '巡更巡检任务列表',
    fcu: '风机旁管',
    bacMobile: '楼宇自控手机端',
    bacMobileIndex: '自控首页',
    bacMobileRoom: '房间状态',
    bacMobileRoomInfo: '房间详情',
    bacMobileEnvironment: '环境',
    bacMobileSelect: '权限配置',
    bacMobileDevice: '设备',
    bacMobileScreen: '场景',
    bacMobileScreenRoom: '房间场景',
    MobileBacSceneRoomClock: '房间场景定时',
    fcuSysSetting: '系统设置',
    fcuAuthSetting: '权限配置',
    fcuEquMonitor: '设备监控',
    processingList: '巡检查看列表',
    cleaning: '保洁',
    cleaningHome: '保洁首页',
    cleanTaskList: '保洁任务列表',
    shareInvoice: '共享发票',
    externalCleaning: '空间管理信息',
    cleanRoom: '房间详情',
    maintenance: '维护保养',
    insideMaintenance: '内部日期表',
    insideTaskList: '内部保养任务列表',
    handleTask: '保养任务处理',
    mobilePersonal: '人员管理',
    mobilePersonalHome: '人员管理',
    mobileIndex: '人员管理主页面',
    mobileEdit: '人员管理编辑',
    mobilePersonalEdit: '编辑',
    mobileParking: '停车服务手机端',
    mobileParkingIndex: '停车服务手机首页',
    mobileParkingVisitor: '停车预约',
    mobileParkingInvitation: '停车邀约',
    mobileParkingHistory: '历史记录',
    newParkingManage: '停车管理',
    parkingDeptSetting: '部门设置',
    personalManage: '人员审核',
    parkingLotManage: '车场管理',
    personalManageIndex: '人员审核',
    parkingOpenVisitor: '停车预约',
    xgParkingVisitor: '新港停车预约',
    xgApproval: '新港停车审批',
    parkingApproval: '停车审批',
    personalManageSetting: '人员审核设置',
    mobileStaff: '手机端-职员管理',
    mobileStaffIndex: '职员管理',
    mobileStaffApplication: '入职申请',
    mobileApplicationIndex: '入职申请',
    mobileStaffEdit: '职员编辑',
    mobileApplicationDetail: '人员详情',
    mobileLeave: '离职管理',
    mobileLeaveIndex: '离职人员',
    mobileLeaveInIndex: '在职人员',
    mobileAssignment: '我的任务',
    mobileAssignmentIndex: '我的任务',
    mobileAssignmentDetail: '任务详情',
    txCodeStatistical: '腾讯一码通统计',
    Staff: '职员管理',
    StaffIndex: '职员管理',
    mobileDepartment: '部门调整',
    mobileDepartmentIndex: '部门调整',
    mobileRegular: '实习转正',
    mobileRegularIndex: '实习转正',
    mobileTask: '任务配置',
    mobileTaskIndex: '任务配置',
    mobileTaskDetail: '任务详情',
    mobileTaskAll: '任务配置',
    mobileTaskAllIndex: '任务配置',
    // 员工天地
    zone: '员工天地',
    zoneIndex: '员工天地首页',
    zoneActivity: '活动管理',
    zoneRegister: '报名管理',
    zoneModel: '模板管理',
    zoneAgenda: '工作日程',
    zoneDesigner: '模板设计',
    zoneComponent: '组件管理',
    clerkIndex: '职员管理',
    zoneActivityIndex: '活动中心',
    zoneActivityCreate: '创建活动',
    zoneRegisterCreate: '我要报名',
    zoneActivityDetail: '活动详情',
    zoneRegisterDetail: '报名详情',
    zoneAgendaEdit: '创建日程',
    activityCenter: '活动中心',
    // 工作流程
    workflow: '工作流程',
    process: '流程',
    // 污水监控
    sewage: '污水监控',
    mobileSewageIndex: '污水监控',
    mobileSewageDetail: '污水监控详情',
    management: '后台管理',
    information: '仪表信息管理',
    itemInformation: '项目信息',
    consumptionanalysis: '能耗数据分析',
    energyconsumption: '分区能耗',
    ltemizedconsumption: '分项能耗',
    sharingcomption: '分时能耗',
    yearanalysis: '能耗对标',
    monthconsumption: '能耗排名',
    benchmarkingcomption: '能耗同比',
    rankingcomption: '能耗环比',
    companyEnergyConsumption: '公司能耗',
    companyManagement: '公司管理',
    monitor: '融合中心',
    monitorTwo: '工艺视图',
    monitorThree: '管理视图',
    sewageImg: 'BIM示意图',
    queryData: '实时监控',
    QueryData: '实时监控',
    periodicMonitoring: '周期监控',
    queryEnergy: '设备工况',
    generalSituation: '设备状态',
    queryEquipment: '用电详情',
    queryCaution: '告警系统',
    QueryCaution: '告警处理',
    overrunTime: '超限时长',
    malfunctionTime: '设备告警',
    equipmentAlarm: '预警信息',
    statement: '数据报表',
    Statement: '排水指标',
    operationalStates: '运行工况',
    runningTime: '设备运行时长统计',
    // statisticalData: '处理工艺统计',
    hj212: 'HJ212数据接入状态',
    mqtt: 'mqtt数据解析',
    mqttFactor: '因子列表',
    mqttId: '权限配置',
    artifact: '产出物',
    consumables: '消耗品',
    backSetting: '后台设置',
    informationSetting: '医院信息设置',
    warningSetting: '预警阈值设置',
    administrationSeeting: '其它数据设置',
    unitsChange: '因子单位修改',
    administration: '管理视图',
    newMonitorOne: '工艺视图',
    allMonitor: '全流程工艺视图',
    monitoringPlatform: '数据监控平台',
    everyOperation: '日常运营报表',
    displacement: '排水量',
    reportIndex: '上报区域配置',
    recordStatement: '运维记录表',
    waterStatement: '水质检测、药剂用量',
    systemDeviceStatement: '系统设施设备日常',
    protectiveStatement: '防护用品易损易耗',
    wasteStatement: '工艺废弃物处置',
    // 城市治理
    cityGoverning: '城市治理',
    cityIndex: '事件选择',
    reportIncident: '上报事件',
    historicalEvents: '历史事件',
    reportDetails: '上报详情',
    sewageLogin: '污水登录页面',
    sewageDataSetting: '后台数据设置',
    // 创技窗户
    cjWindows: '创济窗户',
    cjWindowsIndex: '创济窗户-首页',
    // 赵巷机器人
    robotManage: '智慧机器人',
    robotIndex: '首页',
    // 能耗实时监测
    realTimeMonitoringOfEnergyConsumption: '能耗实时监测',
    buildingEnergyConsumption: '建筑能耗',
    myParkUserSetting: '员工管理',
    // 能源管控
    energyControl: '能源管控',
    energyCategory: '能源管控类别',
    coolingSystem: '能源管控系统',
    equipmentOperationMonitoring: '设备运行监测',
    // coolingSystem: '低区供冷系统',
    // plateSystem: '板交系统',
    // heatPump: '高区热泵系统',
    // thermalStorage: '低区蓄热系统',
    energyReal: '能源管控实时数据',
    // 久事能源
    energyAlarm: '能耗告警管理',
    alarmRuleSetting: '告警规则设定',
    realTimeAlarm: '实时告警',
    historyAlarm: '全部告警',
    energyMonitoring: '能耗实时监测',
    buildingConsumption: '建筑能耗',
    leakingStatistics: '漏水统计',
    energyFluxDensity: '能流密度',
    powerDistribution: '配电图',
    electricPower: '电力参数',
    waterPower: '水力参数',
    fuelGasPower: '燃气参数',
    coldAndHot: '冷热量参数',
    environmentalMonitoring: '环境监测',
    equipmentEnergMonitor: '重点设备能耗监测',
    conditionMonitoring: '重点设备运行状态监测',
    buildingEnergy: '能耗预测',
    consumptionPrediction: '建筑能耗预测',
    diagnosisEnergySaving: '节能诊断',
    freshAirMonitoring: '新风过滤监测',
    heatLossMonitoring: '冷站热损监测',
    equipmentAgingMonitoring: '冷冻机组老化监测',
    coolingTowerMonitoring: '冷却塔性能监测',
    condenserCleaningSuggestion: '冷凝器清洗建议',
    evaporatorCleaningSuggestion: '蒸发器清洗建议',
    operationLogs: '操作日志',
    realTimeAlarmInformation: '实时告警信息',
    alarmRecords: '告警记录',
    // 天鹤能源
    energyNy: '能耗实时监测',
    energyAlarmNy: '能耗告警管理',
    consumptionanalysisNy: '能耗数据分析',
    buildingEnergyNy: '能耗预测',
    diagnosisEnergySavingNy: '能耗预测',
    managementNy: '后台管理',
    rateIndex: '费率设置',
    mobileEnergy: '能源报表',
    EnergyIndex: '首页',
    setMeterReading: '集抄表',
    // 会展
    strategyIndex: '会议室规则',
    settingsIndex: '会议室管理',
    meeting: '会议预约台账',
    exhibitionManage: '会展管理',
    exhibitionIndex: '会展信息',
    meetingRoom: '会议管理',
    appointIndex: '会议预约',
    meetingIndex: '会议日程',
    meetingRoomdetail: '会议预约详情',
    meetingRoomIndex: '会议管理首页',
    appointMyIndex: '我的预约',
    exhibitionManagement: '会议审批',
    // 隔离点小程序
    quarantine: '首页',
    quarantineArea: '青源云隔离点监测',
    quarantineDetail: '今日报告',
    quarantineHistory: '历史报告',
    quarantineAll: '近15天报告',
    quarantineLogin: '青源云隔离监测平台',
    quarantineExcel: '月度报表',
    quarantineInfo: '公司资质',
    quarantineUser: '详细资料',
    quarantineReport: '检测报告',
    regulation: '数据监管',
    userAuthority: '监管区域配置',
    regionalInfo: '区域管理',
    examiningReport: '检测报告管理',
    uploadManagement: '上传管理',
    queryManagement: '查询管理',
    quarantineTransfer: '转运点污水日处理',
    reportedData: '数据上报',
    dataModification: '上报数据修改',
    dataOverview: '数据查询',
    periodQuery: '时期查询',
    preciseQuery: '精确查询',
    // 医院监测平台
    emissionPermit: '排污许可监测报告管理',
    emissionPermitUpload: '上传管理',
    everydayOperation: '日常运营报告管理',
    emissionPermitDownload: '报表导出',
    hospitalSupervising: '医院监管',
    hospitalManagement: '医院管理',
    hospitalUser: '人员权限管理',
    downloadCode: '导出医院二维码',
    equipmentState: '设备工况配置',
    redis: 'redis配置信息',
    // FM三方跳转消防
    fmFireTripartite: 'FM消防',
    eewEquipment: 'eew设备工况',
    equipmentSearch: '设备查询',
    equipmentDetails: '设备详情',
    // 油烟在线监测平台

    // 系统菜单
    systemMenu: '系统菜单',
    // 在线监测
    lampblack: '在线监测',
    amapMonitor: '地图监测',
    monitorData: '实时数据监测',
    // 统计分析
    statisticAnalysis: '统计分析',
    linkageRatio: '联动比查询',
    cleanSearch: '浓度查询',
    abnormalData: '异常数据统计',
    statusCount: '运行状态统计',
    // 周报管理
    weekreport: '周报管理',
    foodCheck: '餐饮初查',
    terminal: '终端安装',
    terminalKeep: '终端维保',
    // 故障管理
    troubleManage: '故障管理',
    terminalFail: '终端故障',
    cleanerFail: '净化器故障',
    // 月度报表
    monthlyReport: '月度报表',
    cleanReport: '站点统计',
    cleanTypeReport: '状态报表',
    // 后台管理
    bgManagement: '后台管理',
    boxManagement: '设备箱子管理',
    equipManagement: '设备管理',
    inforManagement: '饭店信息管理',
    MobileQrCode: '访客二维码',
    // 长三角大屏
    monitorRever: '数字化科创服务平台',
    monitorSupervision: '青源云医疗系统污水在线监管平台',
    // 静安大悦城南楼
    newMonitorJADYC: '静安大悦城一南楼一-工艺流程图',
    monitorJADYC: '静安大悦城-今日水质',
    videoWall: '视频监控-视频墙模式',
    safetyProduction: '安全生产',
    confinedSpaces: '有限空间文件管理',
    codeAdministration: '集团型一码通管理平台',
    // 静安大悦城北楼
    newMonitorJADYCNorth: '静安大悦城一北楼一-工艺流程图',
    monitorJADYCNorth: '静安大悦城-北楼-今日水质',
    videoWallNorth: '北楼-视频监控-视频墙模式',
    safetyProductionNorth: '北楼-安全生产',
    // 工单管理
    workOrder: '工单管理',
    networking: '联网数据',
    deviceControl: '设备控制',
    // AI能效
    aiEnergyEfficiency: 'AI能效',
    aiSupervisoryControl: '分析评价',
    energyConsumption: '能耗',
    modelOfOrdinaryAirConditioningBox: '普通空调箱能耗机理模型',
    aIAlgorithmPlatform: 'AI算法平台',
    wordExpo: 'aiSecurityImg',
    // 借展出海
    dlgexpdetailforzyy: '借展出海'
  },
  tips: {
    sls: '语言切换成功'
  },
  button: {
    add: '新建',
    edit: '编辑',
    view: '查看',
    create: '创建',
    delete: '删除',
    more: '更多',
    setting: '配置',
    valid: '生效',
    invalid: '失效',
    all: '全选',
    none: '全不选',
    download: '下载',
    upload: '上传',
    authority: '权限',
    exportButton: '权限',
    trafficOverview: '单一流量概览'
  },
  base: {
    playVideo: '在线播放',
    add: '新建',
    edit: '编辑',
    view: '查看',
    unzip: '解压',
    empty: '暂无数据',
    iBuilding: '智慧楼宇',
    delete: '删除',
    cancel: '取消',
    sure: '确认',
    reLogin: '重新登录',
    logoutConfirm: '您已注销，您可以取消以停留在此页，或重新登录',
    warn: '警告',
    operation: '操作',
    enable: '启用',
    forbidden: '禁用',
    deleteConfirm: '此操作将永久删除该信息, 是否继续?',
    search: '搜索',
    tips: '提示',
    import: '导入',
    yes: '是',
    no: '否',
    none: '无',
    unknown: '未知',
    submit: '提交',
    send: '发送',
    addNew: '新增按钮',
    newButton: '新增',
    updateButton: '更新',
    selectDate: '选择日期',
    select: '请选择',
    input: '请输入',
    quit: '退出',
    fileUpload: '文件上传',
    enclosure: '附件',
    uploading: '上传中...',
    playImage: '预览大图',
    play: '在线预览',
    waiting: '等待中',
    close: '关闭',
    download: '下载',
    downloading: '下载中...',
    remark: '备注',
    remarkTips: '请输入备注',
    dispose: '配置',
    other: '其他',
    isCancel: '全部失效',
    otherDispose: '更多配置',
    effect: '生效',
    derive: '导出',
    back: '返回',
    pushTime: '推送时间',
    allEffect: '全部生效',
    month: '月',
    allDispose: '批量设置',
    normal: '正常',
    unusual: '异常',
    online: '在线',
    offline: '离线',
    alarm: '报警',
    setUp: '设置'
  },
  navbar: {
    dashboard: '首页',
    github: '项目地址',
    logOut: '退出登录',
    profile: '个人中心',
    theme: '换肤',
    size: '布局大小',
    updPwd: '修改密码'
  },
  dashboard: {
    qrCode: '扫一扫',
    repairTotal: '工单总数',
    todayRepairTotal: '今日工单总数',
    targetExplain: '指标说明',
    userTotal: '用户总数',
    propertyTotal: '物业数',
    todayUserIncrease: '今日用户增长',
    tenantTotal: '租户数',
    equipmentTotal: '设备总数',
    servicePhone: '人工服务',
    todayEquipmentIncrease: '今日用户增长',
    workPanel: '工作面板',
    task: '待办',
    completed: '已办',
    message: '消息',
    annualStatisticsForRepair: '年度工单统计',
    repairGood: '已修好',
    repairBad: '未修好',
    total: '合计'
  },
  profile: {
    company: '公司',
    department: '部门',
    username: '用户名',
    telephone: '手机号'
  },
  login: {
    title: '楼宇智慧管理运营系统',
    logIn: '登录',
    username: '账号',
    password: '密码',
    phone: '手机号',
    code: '验证码',
    thirdparty: '第三方登录',
    userBinding: '用户绑定',
    thirdpartyTips: '本地不能模拟，请结合自己业务进行模拟！！！'
  },
  documentation: {
    documentation: '文档',
    github: 'Github 地址'
  },
  permission: {
    addRole: '新增角色',
    editPermission: '编辑权限',
    roles: '你的权限',
    switchRoles: '切换权限',
    tips: '在某些情况下，不适合使用 v-permission。例如：Element-UI 的 el-tab 或 el-table-column 以及其它动态渲染 dom 的场景。你只能通过手动设置 v-if 来实现。',
    delete: '删除',
    confirm: '确定',
    cancel: '取消'
  },
  guide: {
    description: '引导页对于一些第一次进入项目的人很有用，你可以简单介绍下项目的功能。本 Demo 是基于',
    button: '打开引导'
  },
  components: {
    documentation: '文档',
    tinymceTips: '富文本是管理后台一个核心的功能，但同时又是一个有很多坑的地方。在选择富文本的过程中我也走了不少的弯路，市面上常见的富文本都基本用过了，最终权衡了一下选择了Tinymce。更详细的富文本比较和介绍见',
    dropzoneTips: '由于我司业务有特殊需求，而且要传七牛 所以没用第三方，选择了自己封装。代码非常的简单，具体代码你可以在这里看到 @/components/Dropzone',
    stickyTips: '当页面滚动到预设的位置会吸附在顶部',
    backToTopTips1: '页面滚动到指定位置会在右下角出现返回顶部按钮',
    backToTopTips2: '可自定义按钮的样式、show/hide、出现的高度、返回的位置 如需文字提示，可在外部使用Element的el-tooltip元素',
    imageUploadTips: '由于我在使用时它只有vue@1版本，而且和mockjs不兼容，所以自己改造了一下，如果大家要使用的话，优先还是使用官方版本。'
  },
  table: {
    dynamicTips1: '固定表头, 按照表头顺序排序',
    dynamicTips2: '不固定表头, 按照点击顺序排序',
    dragTips1: '默认顺序',
    dragTips2: '拖拽后顺序',
    title: '标题',
    importance: '重要性',
    type: '类型',
    remark: '点评',
    search: '搜索',
    add: '添加',
    export: '导出',
    reviewer: '审核人',
    id: '序号',
    date: '时间',
    author: '作者',
    readings: '阅读数',
    status: '状态',
    actions: '操作',
    edit: '编辑',
    publish: '发布',
    draft: '草稿',
    delete: '删除',
    cancel: '取 消',
    confirm: '确 定'
  },
  example: {
    warning: '创建和编辑页面是不能被 keep-alive 缓存的，因为keep-alive 的 include 目前不支持根据路由来缓存，所以目前都是基于 component name 来进行缓存的。如果你想类似的实现缓存效果，可以使用 localStorage 等浏览器缓存方案。或者不要使用 keep-alive 的 include，直接缓存所有页面。详情见'
  },
  errorLog: {
    tips: '请点击右上角bug小图标',
    description: '现在的管理后台基本都是spa的形式了，它增强了用户体验，但同时也会增加页面出问题的可能性，可能一个小小的疏忽就导致整个页面的死锁。好在 Vue 官网提供了一个方法来捕获处理异常，你可以在其中进行错误处理或者异常上报。',
    documentation: '文档介绍'
  },
  excel: {
    export: '导出',
    selectedExport: '导出已选择项',
    placeholder: '请输入文件名(默认excel-list)'
  },
  zip: {
    export: '导出',
    placeholder: '请输入文件名(默认file)'
  },
  pdf: {
    tips: '这里使用   window.print() 来实现下载pdf的功能'
  },
  theme: {
    change: '换肤',
    documentation: '换肤文档',
    tips: 'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo'
  },
  menus: {
    title: '菜单配置',
    searchHolder: '搜索菜单名称',
    menuName: '菜单名称',
    menu: '菜单',
    url: '地址',
    icon: '图标',
    status: '状态',
    script: '脚本',
    order: '序号',
    parent: '父级菜单',
    menuAction: '菜单按钮',
    action: '按钮',
    menuVisible: '显示'
  },
  action: {
    action: '按钮',
    actionName: '按钮名称',
    sort: '显示顺序',
    actionKey: '标识',
    actionIcon: '图标',
    status: '状态'
  },
  role: {
    title: '角色设置',
    role: '角色',
    searchHolder: '搜索角色名称',
    roleName: '角色名称',
    roleScription: '角色描述',
    script: '角色描述',
    searchUser: '搜索用户名称'
  },
  equipment: {
    name: '设备',
    manage: {
      showVideo: '播放视频',
      title: '设备管理',
      eiNumber: '设备编号',
      number: '编号',
      esOfSystem: '所属系统',
      space: '空间位置',
      eiName: '设备名称',
      eiType: '设备类型',
      eiAttribute: '设备属性',
      isPatrol: '是否制定巡检计划',
      isMaintenance: '是否制定维保计划',
      sysType: '系统分类',
      brand: '品牌',
      ofType: '所属分类',
      eiModel: '设备型号',
      prop: '属性',
      downloadQR: '下载二维码',
      printQR: '打印二维码',
      promptContext: '请输入属性名称',
      promptTitle: '添加属性',
      basicsInfo: '基本信息',
      installDate: '安装日期',
      factoryDate: '出厂日期',
      manageDept: '管理部门',
      supplier: '维保单位',
      supplierContact: '维保单位联系方式',
      remark: '备注',
      partsInfo: '配件信息',
      partsAttached: '配件附件',
      eiMaterial: '设备材料',
      addSpare: '添加备品'
    }
  },
  equipmentsystem: {
    title: '智慧运营系统字典表',
    type: '类型',
    sysname: '名称',
    fathername: '上级分类名称',
    isurgent: '是否紧急',
    fatherNode: '一级菜单名称',
    node: '二级菜单名称'
  },
  repair: {
    title: '维修管理',
    repairnumber: '工单号',
    exhibitionNumber: '展位号（报修区域）',
    repairUnit: '报修单位',
    einame: '设备名称',
    repairEquipType: '设备类型',
    repairType: '报修类型',
    space: '报修地址',
    roomid: '报修房间',
    warningType: '报警类型',
    warningName: '报警数组名称',
    warningValue: '报警数值',
    failurenote: '故障描述',
    dispatch: '报修分派',
    esidname: '故障类别',
    repairer: '报修人',
    status: '状态',
    einumber: '设备号',
    repaired: '维修物',
    repairerphone: '手机号',
    failuretime: '报修时间',
    esid: '故障类别',
    dailogTypeEdit: '编辑',
    dailogTypeView: '基本信息',
    dailogTypeNew: '新增报修',
    typeNew: '新增',
    anName: '处理节点',
    asName: '阶段名称',
    index: '序号',
    satisfactionlevel: '客户评价满意等级',
    opinionInfo: {
      satisfactionlevel: '客户满意度',
      speedlevel: '维修速度',
      segment1: '评价意见',
      createdBy: '评价人'
    },
    template: {
      button: {
        default: '恢复默认'
      },
      repairType: '报修模板',
      recordType: '维修模板',
      title: '编辑报修模板',
      name: '模板名称',
      type: '模板类型',
      approval: '模板名称',
      properties: '工单属性',
      preview: '表单预览'
    },
    property: {
      title: '编辑属性字典',
      name: '属性名称',
      field: '字段名称',
      value: '默认数值',
      type: '数值类型',
      isEdit: '允许编辑',
      isNeed: '是否必选'
    }
  },
  alarmView: {
    arming: '消警',
    title: '物联监测数据总览',
    allCount: '设备总数',
    onlineCount: '在线设备数',
    onlineCountNew: '在线',
    offlineCount: '离线设备数',
    offlineCountNew: '离线',
    warnCount: '告警设备数',
    warnCountNew: '告警',
    normal: '正常',
    watch: '查看>',
    alarmName: '告警事件',
    startAndEndTime: '时间',
    type: '类别',
    space: '位置',
    alarmNameAndValue: '数值/状态',
    alarmSpaceName: '报警位置',
    alias: '安装位置',
    number: '编号',
    status: '状态',
    operatingStatus: '操作状态',
    equipmentStatus: '设备状态',
    sensorNumber: '传感器编号',
    time: '时间',
    emergencyLevel: '紧急程度',
    delegate: '委派',
    repair: '报修',
    warnLimit: '正常范围',
    zsmType: '传感器类型',
    description: '查看',
    uid: '设备号',
    battery: '电量%',
    isDistort: '是否为误报'
  },
  securityAlarmView: {
    title: '安防监测数据总览',
    allCount: '设备总数',
    onlineCount: '在线设备数',
    onlineCountNew: '在线',
    offlineCount: '离线设备数',
    offlineCountNew: '离线',
    warnCount: '告警设备数',
    warnCountNew: '告警',
    normal: '正常',
    watch: '查看>',
    alarmName: '告警事件',
    startAndEndTime: '时间',
    type: '类别',
    space: '位置',
    alarmNameAndValue: '数值/状态',
    alarmSpaceName: '报警位置',
    alias: '安装位置',
    number: '编号',
    status: '状态',
    equipmentStatus: '设备状态',
    sensorNumber: '传感器编号',
    deviceNumber: '设备编号',
    deviceId: '设备编号',
    deviceName: '设备名称',
    deviceType: '设备类型',
    floor: '楼层',
    time: '时间',
    emergencyLevel: '紧急程度',
    delegate: '委派',
    processOneKey: '一键处理',
    viewImg: '查看图片',
    repair: '报修',
    warnLimit: '正常范围',
    zsmType: '传感器类型',
    description: '查看',
    uid: '设备号',
    buildName: '楼宇名称',
    alarmSubType: '告警类型',
    alarmTime: '告警时间',
    id: '编号',
    alarmNum: '未处理告警数量'
  },
  space: {
    searchHolder: '搜索空间名称',
    spaceName0: '区域名称',
    spaceName1: '建筑名称',
    spaceName2: '楼层名称',
    spaceName3: '房间名称',
    spaceNumber0: '区域编号',
    spaceNumber1: '建筑编号',
    spaceNumber2: '楼层编号',
    spaceNumber3: '房间编号',
    area2: '楼层面积',
    area3: '房间面积',
    spaceType3: '房间类型',
    roomUID3: '房间UID',
    roomType3: '房间类型',
    roomDeviceType3: '设备间类型'
  },
  spare: {
    title: '备件管理',
    spareRow: {
      spnumber: '备品编号',
      belongSystem: '所属系统',
      spname: '备品名称',
      spformat: '备品规格',
      spunit: '备品单位',
      spamount: '备品数量',
      spwarningamount: '备品缺货告警',
      note: '备注',
      spWarnPerson: '告警推送人员',
      spExpectNumber: '备品期望数量',
      spUnitPrice: '备品单价'
    },
    dailogTypeNew: '新增备件',
    dailogTypeEdit: '编辑备件',
    dailogTypeView: '查看备件',
    spareOperationButton: '备品管理',
    viewSpare: '备品查看',
    updateSpare: '备品更新',
    deleteSpate: '备品删除',
    creaseSpareWarehouse: '备品入库',
    decreaseSpareWarehouse: '备品出库',
    viewSpareWarehouse: '库存流水',
    selectNotice: '请选择'
  },
  company: {
    title: '公司设置',
    searchCompany: '搜索公司名称',
    organizationName: '组织名称',
    type: '类型',
    status: '状态',
    company: '公司',
    companyName: '公司名称',
    companyEngName: '公司英文名',
    companyLessName: '公司名缩写',
    companyAddress: '公司地址',
    companyCorPoration: '公司法人',
    unifedsocialCreditcode: '公司执照',
    establish: '公司成立时间',
    parent: '上级组织'
  },
  department: {
    title: '部门设置',
    searchDepart: '搜索部门名称',
    department: '部门',
    departName: '部门名称',
    departmentAddress: '部门地址',
    parent: '上级组织',
    status: '状态'
  },
  user: {
    user: '用户',
    title: '人员设置',
    userLogo: '头像',
    userName: '用户名称',
    mobile: '电话号码',
    email: '邮箱',
    sex: '性别',
    status: '状态',
    parent: '部门',
    upUserLogo: '上传头像',
    partTimeParent: '兼职部门'
  },
  sparehisinfo: {
    spamount: '备品原有数量',
    amount: '增加备品数量',
    deamount: '减少备品数量',
    note: '备注',
    userName: '使用人姓名',
    plase: '使用地址',
    action: '操作',
    createon: '时间',
    createby: '操作人'
  },
  maintenancePersonnelManagement: {
    title: '人员管理',
    newForm: '新增人员',
    viewForm: '查看人员',
    updateForm: '更新人员',
    maintenanceRow: {
      number: '序号',
      searchType: '查询类别',
      faultName: '故障名称',
      name: '姓名',
      troubleshooting: '故障维修',
      faultDetection: '故障检验',
      personstatus: '人员状态',
      personstatus1: '正常工作',
      personstatus2: '请假',
      personstatus3: '工作量大',
      personstatus4: '其它',
      operation: '操作',
      selectById: '查看',
      update: '更新',
      delete: '删除'
    },
    selectNotice: '请选择'
  },
  maintenanceManagement: {
    maintenanceRow: {
      faultName: '故障名称',
      shootingPeopleCount: '维修人数量',
      detectionPeopleCount: '检验人数量'
    },
    title: '故障维修一览'
  },
  log: {
    title: '日志查询'
  },
  spaceManage: {
    title: '空间管理',
    buttonAddNewBuilding: '新建建筑',
    querySpaceName: '位置查询'
  },
  wxuser: {
    openid: 'OpenID',
    nickname: '昵称',
    phone: '手机号',
    country: '来自',
    province: '',
    city: '',
    sex: '性别',
    headpic: '头像',
    mptype: '所属公众号',
    unionId: 'UnionID',
    operation: '操作权限'
  },
  worksheet: {
    title: '工单报修',
    warranty: '报修物',
    deviceNumber: '设备号',
    repairAddress: '报修地址',
    exhibitionNumber: '展位号（报修区域）',
    repairEquipment: '报修设备',
    failureTime: '报修时间',
    preRepairTime: '预约维修时间',
    failureType: '故障类别',
    failureScript: '故障描述',
    repairPeople: '报修人',
    repairPhone: '报修人联系方式',
    repairUnit: '报修单位'
  },
  workDetail: {
    title: '查看明细',
    number: '工单号',
    reserveTime: '预约时间',
    repairMessage: '报修信息',
    repairPeople: '报修人',
    phone: '报修人手机号',
    dateTime: '报修时间',
    address: '报修地址',
    equipment: '报修设备',
    script: '故障描述',
    isNormal: '是否修好',
    isPay: '是否收费',
    cost: '费用(元)',
    payDetail: '费用明细',
    addRemark: '添加备注',
    serviceTime: '维修时间',
    failureCause: '故障原因',
    type: '故障类别',
    repairEquipType: '设备类型',
    taskMessage: '任务分派信息',
    dept: '维修管理岗位',
    serviceNote: '维修备注',
    taskStatus: '任务分派状态',
    maintainMessage: '维修信息',
    maintainPeople: '维修人员',
    maintainStatus: '维修状态',
    testMessage: '检验信息',
    testPeople: '检验人员',
    testStatus: '检验状态',
    test: '评价',
    degree: '满意度',
    speed: '响应速度'
  },
  workList: {
    title: '工单列表',
    workStatus: '工单状态'
  },
  deviceDeployment: {
    title: '安防监测楼层',
    index: '编号',
    server: '设备编号',
    name: '设备名称',
    floor: '设备楼层',
    model: '型号',
    count: '数量',
    type: '类型',
    address: '位置',
    viewAudio: '查看实时视频',
    receiveMsgType: 'AI功能'
  },
  fireDeviceDeployment: {
    title: '消防监测楼层',
    title1: '消防监测楼层',
    title2: '传感器类型'
  },
  monitorSystem: {
    title1: '物联监测楼层',
    title2: '传感器类型',
    index: '编号',
    server: '服务器/产品名称',
    model: '型号',
    count: '数量',
    type: '类型',
    address: '位置'
  },
  realTimeMonitor: {
    title: '设备类别'
  },
  task: {
    list: {
      processed: '已处理',
      processing: '处理中',
      repairNumber: '工单号',
      taskType: '任务类型',
      equipment: '设备',
      lookMore: '查看详情'
    },
    detail: {
      basicInfo: '基础信息',
      progressInfo: '进度信息',
      imageInfo: '图片详情',
      repairInfo: '维修详情',
      stage: '阶段',
      statement: '撤销',
      transfer: '移交',
      assign: '指派',
      handle: '处理',
      allSend: '群发',
      examination: '审批',
      approval: '同意',
      reassign: '重新指派',
      accept: '接单',
      stageTransfer: '阶段移交',
      personAssign: '指派人员',
      regression: '回退',
      customerServiceStage: '客服处理阶段',
      allocationStage: '经理分派阶段',
      repairStage: '维修处理阶段',
      auditStage: '检验处理阶段',
      isFree: '是否收费'
    }
  },
  history: {
    title: '新增维修记录'
  },
  housekeepManage: {
    hkmSpaceName: '模板名称',
    title1: '保洁配置',
    title2: '保洁任务',
    title3: '保洁排班',
    title4: '保洁模板',
    index: '序号',
    spaceName: '空间类型名称',
    spaceName2: '空间名称',
    checkCycle: '保洁周期',
    isDeploy: '是否配置',
    isVaild: '是否生效',
    schedulingType: '排班名称',
    startTime: '开始时间',
    endTime: '结束时间',
    principal: '负责人',
    groupPerson: '班组成员',
    startAndEndTime: '排班时间',
    madePeople: '制定人',
    checkPeople: '选定人员',
    timeLimit: '限定时间',
    system: '系统',
    inputValue: '请输入名称',
    message: '信息',
    inspectionName: '保洁项目名称',
    inspectType: '保洁类型',
    address: '位置',
    isEffect: '是否生效',
    inspectionTime: '保洁时间',
    subject: '保洁项目',
    hkctGpId: '保洁班组',
    inspectionSum: '保洁项数量',
    status: '状态',
    completeTime: '完成时间',
    inspectionResult: '保洁结果',
    detail: '明细',
    baseManage: '基本信息',
    inspectionStatus: '保洁状态',
    inspectionPoint: '保洁项',
    input: '请输入',
    inspectionAddress: '位置',
    addTask: '新增任务',
    housekeep: '保洁项',
    nowDateCount: '当日保洁次数',
    team: '班组',
    room: '房间',
    roomType3: '房间类型',
    createdBy: '创建人',
    classGroupName: '设定班组:   ',
    hkmIsDeploy: '是否生效',
    hkmSpaceId: '空间类型',
    hkmName: '模板名称',
    hkdName: '房间名称',
    hkdLocaltionName: '位置名称'
  },
  patrolManage: {
    title1: '巡检配置',
    title2: '巡检任务',
    title3: '巡检排班',
    title4: '巡检模板',
    index: '序号',
    name: '名称',
    format: '业态',
    checkCycle: '巡检周期',
    cycle: '周期',
    isDeploy: '是否配置',
    typeCode: '分类编码',
    address: '位置',
    isEffect: '是否生效',
    inspectionTime: '巡检时间',
    subject: '巡检项目',
    pctGpId: '巡检班组',
    inspectionType: '巡检类型',
    inspectionSum: '巡检点数量',
    status: '状态',
    completeTime: '完成时间',
    inspectionResult: '巡检结果',
    schedulingType: '排班类型',
    startTime: '开始时间',
    endTime: '结束时间',
    assigner: '制定人',
    type: '类型',
    system: '系统',
    area: '区域',
    inspectionAddress: '巡检位置',
    inspectionStatus: '巡检状态',
    startAndEndTime: '排班时间',
    madePeople: '制定人',
    timeLimit: '限定时间',
    checkPeople: '选定成员',
    inputValue: '请输入名称',
    message: '信息',
    setNumber: '排序号',
    inspectionName: '巡检点任务',
    inspectType: '巡检点任务类型',
    isIntelligent: '是否智能化',
    deviceUseCode: '设备采集点编码',
    deviceCode: '设备编码',
    isWarn: '是否报警',
    inspectionClass: '责任班组',
    commonHigh: '数值上限',
    commonLow: '数值下限',
    warnHigh: '报警阀值上限',
    warnLow: '报警阀值下限',
    input: '请输入',
    detail: '详情',
    baseManage: '基本信息',
    inspectionPoint: '巡检点',
    acquisitionValue: '采集数值',
    deviceGatherNumber: '设备采集点编码',
    createConfig: '创建配置',
    pdmTypeNumber: '分类编号',
    pdmName: '模板名称',
    pdmIsDeploy: '是否配置',
    pdmBelongId: '所属系统',
    createDetail: '创建细节',
    pddDeviceName: '设备',
    pdrmBusinessTypeNumber: '设备间业态',
    equipmentRoomType: '设备间类型',
    pdrdDeviceroomName: '设备间',
    patrolResult: '巡检结果',
    note: '备注',
    patrolHkClass: '保洁班组',
    CreateInspectionSystem: '创建巡检系统'
  },
  roomPatrol: {
    index: '序号',
    tab0: '任务看板',
    tab1: '任务管理',
    tab2: '事项管理',
    tab3: '房间管理',
    tab4: '排班管理'
  },
  visitor: {
    visitId: '访问Id',
    buildingName: '楼宇名称',
    interviewedCompanyId: '被访公司id',
    interviewedCompanyName: '被访公司名称',
    visitCancelInfo: '预约被取消，此二维码无效',
    to: '到',
    visitorId: '访客id',
    visitorName: '访客名称',
    visitorPhone: '访客手机号',
    visitorCardType: '证件类型',
    visitorCardNum: '证件号',
    visitTime: '预约时间',
    visitorCode: '访客码',
    leaveTime: '预离时间',
    intervieweeId: '被访人id',
    intervieweeName: '被访人名字',
    intervieweePhone: '被访人手机号',
    visitType: '访问类型',
    visitWay: '访问类型',
    item: '被访事由',
    visitQrId: '一码通返回的预约id',
    visitQrErrorMessage: '一码通返回的错误信息',
    qrCode: '二维码',
    verificationCode: '验证码',
    tips: '温馨提示',
    carno: '车牌号',
    workflowRecordId: '审批流记录表id',
    visitStatus: '审批状态',
    publicId: '公众号id',
    visitorCardTypeName: '证件号类型',
    visitTypeName: '访问类型',
    visitWayName: '访问方式',
    visitStatusName: '审批状态',
    createdBy: '创建人',
    createdOn: '创建时间',
    modifyBy: '更新人',
    modifyOn: '更新时间',
    appointmentInformation: '预约信息',
    appointmentType: '访问类型',
    visitorNum: '来访人数',
    interviewedCompany: '拜访公司',
    refuse: '拒绝',
    accept: '接受',
    approvalInfo: '审批信息',
    cancelInvite: '取消预约',
    approving: '审批中',
    approved: '审批通过',
    approvalFailed: '审批不通过',
    approvedSuccessfully: '审批成功',
    frontDesk: '前台登记',
    selfService: '访客预约',
    tenantInvitation: '租户邀约',
    guestMachineInvite: '访客机预约',
    cancelSuccessInfo: '取消预约成功，已短信通知访客',
    cancelSuccessInfoForInvitor: '取消预约成功，请及时通知访客',
    cancelSuccessInfoForVisitor: '取消预约成功，欢迎下次光临',
    inviteSuccessInfo: '邀约成功，已短信通知访客',
    inviteSuccess: '邀约成功',
    inviteAndApprovalSuccessInfo: '邀约申请已提交,请等待审核',
    draftSuccess: '草稿保存成功，草稿有效期为24小时',
    reservationSuccessInfo: '预约申请已提交,请等待审核',
    cancelSuccess: '取消预约成功',
    cancelFailed: '取消预约失败',
    confirm: '确定',
    back: '返回',
    visitedInfo: '被访信息',
    reservationInfo: '预约信息',
    noData: '暂无数据',
    showLastThreeRecords: '显示最近3条',
    more: '更多',
    visitorInfo: '访客信息',
    visitedName: '被访者姓名',
    visitedPhone: '被访者手机号',
    otherInfo: '其他信息',
    status: '状态',
    appointmentForVisitor: '访客预约',
    appointmentForVisitorDetail: '适用于外来人员拜访预约申请',
    tenantInvitesGuestToVisit: '租户邀约',
    tenantInvitesGuestToVisitDetail: '适用于大楼租户邀请访客前来拜访',
    historicalInformation: '历史信息',
    historicalInformationDetail: '查看预约申请状态',
    tenantCannotMakeAnAppointment: '租户不能预约',
    serverException: '服务器异常',
    noInvitationPermission: '无邀约权限,如果您是大楼用户,请联系前台管理员开通权限',
    initiateAnAppointment: '发起预约',
    saveDraft: '保存草稿',
    pleaseEnterVisitorName: '请输入访客姓名',
    pleaseEnterVisitorPhoneNum: '请输入访客手机号',
    pleaseChooseVisitingTime: '请选择来访时间',
    pleaseEnterTheLicensePlateNumber: '请输入车牌号（选填）',
    cancel: '取消',
    partTimeSelection: '公司兼职选择',
    companySelection: '公司选择',
    pleaseChoose: '请选择',
    temporaryVisitorCode: '临时访客码',
    validPeriod: '有效期:',
    tipsShare: '1.请将当前页面截图分享给访客或通过历史信息截屏分享',
    tipsCode: '2.验证码可通过前台登记打印纸质版访客码',
    visitorNameNotNullCheck: '访客姓名不能为空',
    visitorPhoneNotNullCheck: '手机号不能为空',
    visitTimeNotNullCheck: '访问时间不能为空',
    leaveTimeNotNullCheck: '离访时间不能为空',
    visitorPhoneFormatCheck: '请输入正确的手机号',
    carnoFormatCheck: '请输入正确的车牌号',
    appointmentSuccessful: '预约成功',
    pleaseEnterIntervieweeName: '请输入被访人姓名',
    pleaseEnterVisitedName: '请输入被访人手机号',
    pleaseChooseCompany: '被访人公司选择',
    visitCompanySelection: '访问公司选择',
    intervieweeNameNotNullCheck: '被访人姓名不能为空',
    intervieweePhoneNotNullCheck: '手机号不能为空',
    createRegistrationCodeInformation: '访客注册二维码',
    createRegistrationCodeInformationDetail: '申请访客注册二维码',
    pleaseChooseVisitorDay: '请选择访问日期',
    pleaseEnterVisitorDayCount: '请输入访问持续时间',
    pleaseEnterUsedCount: '请输入允许注册次数',
    pleaseEnterPurpose: '请输入访问目的',
    pleaseEnterVisitorPhone: '请输入访客手机号',
    pleaseChooseVisitorCardType: '请选择证件类型',
    pleaseEnterVisitorCardNum: '请输入证件号',
    pleaseEnterCarno: '请输入车牌号',
    pleaseEnterVisitorNum: '请输入访客人数',
    pleaseEnterVerificationCode: '请输入验证码',
    // buildingName: '通行大厦',
    // intervieweeName: '邀请人',
    // intervieweePhone: '邀请人手机号',
    visitorValid: '访客有效期',
    visitorQrCode: '访客通行二维码',
    visitorDayCountNotNullCheck: '访问持续时间不能为空',
    welcome: '欢迎使用',
    qrCodeForm: {
      visitorDay: '访问日期',
      visitorDayCount: '访问持续时间',
      usedCount: '允许注册次数',
      purpose: '访问目的',
      leaveDay: '预计离开时间'
    },
    visitForm: {
      visitorName: '访客姓名',
      visitorPhone: '访客手机号',
      visitedName: '被访者姓名',
      visitedPhone: '被访者手机号',
      visitTime: '访问时间',
      visitorDayCount: '访问持续时间',
      leaveDay: '预计离开时间'
    },
    registrationForm: {
      visitorName: '访客姓名',
      visitorPhone: '访客手机号',
      visitorCardType: '证件类型',
      visitorCardNum: '证件号',
      carno: '车牌号',
      visitorNum: '访客人数'
    }
  },
  maintenanceManage: {
    title1: '维保任务',
    title2: '维保计划',
    title3: '维保模板',
    title4: '责任班组',
    title5: '外委单位',
    index: '序号',
    responsibleTeamName: '班组名称',
    responsibleTeamPrincipal: '负责人',
    responsibleTeamMember: '班组成员',
    operate: '操作',
    outTeamName: '外委单位名称',
    outTeamLiaison: '联系人',
    outTeamPhoneNum: '联系方式',
    outTeamSystemArrange: '业务范围',
    outTeamAddress: '地址',
    breafInstruction: '简介',
    templateName: '模板名称',
    systemOneLevel: '所属系统',
    templateItem: '模板内容',
    contentItem: '事项',
    contentResult: '结果',
    contentContent: '内容',
    contentRemark: '备注',
    wEquipment: '维保设备',
    wEquipmentOrRoom: '设备/设备间',
    wEquipmentName: '请输入维保设备名称',
    wEquipmentId: '设备编号',
    wInputEquipmentId: '请输入维保设备编号',
    wTime: '维保时间',
    wSelectTime: '请选择维保时间',
    wFinishTime: '完成时间',
    wSelectFinishTime: '请选择完成时间',
    search: '搜索',
    wPlace: '位置',
    equipmentRoomName: '设备间',
    wBelongSystem: '所属系统',
    wSubjectType: '维保类型',
    wSystemType: '系统类型',
    wRecorder: '记录人员',
    wSelect: '请选择',
    wStatue: '状态',
    wDetail: '详情',
    wPending: '待处理',
    wImage: '图片',
    wExplain: '说明',
    wAdd: '新增',
    equipName: '设备名称',
    equipCode: '设备编号',
    equipSpace: '设备位置',
    wStartTime: '维保开始时间',
    wSelectDate: '选择日期',
    wProject: '维保项目',
    wInputProjectName: '请输入维保项目名称',
    wUnit: '维保单位',
    wSearchTemplate: '搜索或者创建模板',
    wPeriod: '维保周期',
    wPeriodDays: '维保周期天数',
    wCreateTask: '生成任务',
    wAhead: '提前',
    wDays: '天',
    wSubmit: '提交',
    wEdit: '编辑',
    wSystemName: '所属系统',
    jan: '1月',
    feb: '2月',
    mar: '3月',
    apr: '4月',
    may: '5月',
    jun: '6月',
    jul: '7月',
    aug: '8月',
    sept: '9月',
    oct: '10月',
    nov: '11月',
    dec: '12月',
    week1: '1周',
    week2: '2周',
    week3: '3周',
    week4: '4周',
    week5: '5周',
    weeklyMaintenance: '周保养',
    halfMonthMaintenance: '半月保养',
    monthMaintenance: '月保养',
    seasonMaintenance: '季保养',
    haleYearMaintenance: '半年保养',
    yearMaintenance: '年保养',
    other: '其他',
    overdue: '逾期',
    finished: '已完成',
    wNoticeSwitch: '开启微信推送',
    wNoticeFrequency: '微信推送频率',
    wYear: '维保年份',
    wScheduleType: '自然周期制(如：周一至周日)',
    wStageSwitch: '定制日程',
    wStageSelect: '选择日程',
    stageTitle: '定制计划管理',
    stage: {
      title: '定制日程表',
      name: '日程表名称',
      type: '日程表类型',
      pickList: '日程表配置',
      remark: '备注',
      header1: '新建日程',
      header2: '编辑&预览',
      contentTitle: '定制日程提示：'
    },
    pick: {
      title: '定制日程事项',
      index: '排序',
      label: '事项名称',
      value: '事项编号',
      start: '开始时间',
      end: '结束时间',
      type: '事项类型',
      parent: '父级事项',
      startAndEnd: '起止日期',
      remark: '备注',
      operate: '操作',
      tip: '请选择',
      header1: '查询列表',
      header2: '编辑&预览',
      itemLevel1: '一级事项',
      itemLevel2: '二级事项',
      itemLevel3: '三级事项'
    }
  },
  carInList: {
    plateNo: '车牌号',
    carType: '车辆类型',
    carInTime: '入场时间',
    entrance: '入口',
    isOut: '是否出场',
    out: '已出场',
    noOut: '未出场',
    vipCar: 'VIP车',
    outByManual: '手动出场'
  },
  carOutList: {
    plateNo: '车牌号',
    carType: '车辆类型',
    carInTime: '入场时间',
    carOutTime: '出场时间',
    exitPlace: '出口',
    cost: '费用',
    vipCar: 'VIP车'
  },
  parkingMonitor: {
    monitoring: '实时监控'
  },
  parkingVisitor: {
    all: '全部',
    waitDown: '待下发',
    downed: '已下发',
    obsolete: '已过时',
    fail: '下发失败',
    plateNo: '车牌号',
    customer: '预约者',
    company: '所属公司',
    tenant: '被访者',
    appointTime: '预约时间',
    source: '来源',
    status: '状态',
    down: '手动下发',
    cancel: '手动取消',
    deleteVisitorInfo: '是否手动取消此车辆预约',
    sendInfo: '是否手动下发此预约'
  },
  fireManagement: {
    title1: '实时监控',
    title2: '设备列表',
    title3: '设备信息',
    title4: '报警数据',
    title5: '历史数据',
    title6: '历史警情',
    deviceName: '设备名称',
    deviceCode: '设备号',
    input: '请输入',
    utcTimeStr: '信号时间',
    modelType: '类型',
    isOnline: '是否在线',
    index: '序号',
    deviceType: '设备分类',
    companyName: '所属企业',
    setAddress: '安装位置',
    dtuCode: 'DTU号',
    DeviceModelType: '设备类型',
    SignalLevel: '信号值',
    deviceStatus: '设备状态',
    FirstDate: '上线日期',
    FactoryDate: '出厂日期',
    ServiceLimit: '设备服务年限',
    address: '位置',
    AlarmTb: '设备报警信息',
    newData: '实时参数',
    DataTypeName: '数据类型名称',
    CircuitNo: '报警通道',
    DataValue: '数值',
    DataState: '数据状态',
    deviceTypeName: '数据类型',
    SubItem: '子设备',
    SubDeviceNumber: '子设备号',
    SubDeviceName: '子设备名称',
    SubType: '子设备分类名称',
    searchTime: '时间范围',
    dataTime: '数据时间',
    dataValue: '数据值',
    alarmMethodName: '报警类别名称',
    alarmTime: '报警时间',
    alarmValue: '报警值',
    alarmTypeName: '报警类型详情',
    alarmStatus: '状态',
    alarmData: '实时报警数据',
    alarmAlertName: '报警类型',
    isAttention: '是否关注'
  },
  visit: {},
  activityList: {
    activitySearch: '按流程名称搜索',
    approval: '审批',
    setActivity: '设置流程',
    menuName: '模板名称',
    activityName: '流程名称',
    selectActivity: '选择或定义审批过程',
    addPhase: '新增阶段',
    addNodes: '新增节点',
    deletePhase: '删除阶段/节点',
    updateMenu: '更新',
    add: '新增',
    cancel: '取消',
    finish: '结束',
    setPhase: '【阶段】设置',
    setNodes: '【节点】设置',
    search: '搜索'
  },
  activity: {
    agName: '组名称'
  },
  handle: {
    template: {
      title: '模板配置',
      number: '编号',
      templateName: '名称',
      type: '类型',
      remarks: '备注'
    }
  },
  baseApp: {
    dataDic: {
      table: {
        dictionaryName: '字典名称',
        code: '字典编号',
        remarks: '描述',
        setting: '字典配置'
      },
      search: {
        dictionaryName: '字典名称:',
        code: '字典编号:',
        dictionaryNamePlaceholder: '请输入字典名称'
      },
      detail: {
        dictionaryName: '字典名称:',
        dictionaryNamePlaceholder: '请输入字典名称',
        dictionaryNameError: '字典名称必须',
        code: '字典编码:',
        codePlaceholder: '请输入字典编码',
        codeError: '字典编码必须',
        remarks: '描述:',
        remarksPlaceholder: '请输描述'
      },
      setting: {
        title: '字典列表'
      },
      value: {
        detailName: '名称:',
        detailNamePlaceholder: '请输入名称:',
        detailNameError: '名称必须',
        value: '数据值:',
        valuePlaceholder: '请输入数据值:',
        valueError: '数据值必须',
        remarks: '描述:',
        orderNo: '排序:',
        used: '是否启用:'
      }
    },
    org: {
      detail: {
        orgName: '机构名称:',
        orgNamePlaceholder: '请输入机构名称',
        orgNameError: '请输入机构名称',
        spaceIdListError: '地址必须填写',
        orgType: '机构类型:',
        mobile: '手机号:',
        mobilePlaceholder: '请输入手机号',
        fax: '传真:',
        faxPlaceholder: '请输入传真',
        address: '地址:',
        addressPlaceholder: '请输入地址',
        description: '描述:',
        descriptionPlaceholder: '请输入描述',
        parentId: '上级机构:',
        spaceIdList: '相关空间:',
        parentIdPlaceholder: '请选择上级机构',
        startTime: '租期开始日:',
        startTimePlaceholder: '请选择租期开始日',
        endTime: '租期结束日:',
        endTimePlaceholder: '请选择租期结束日'
      }
    },
    space: {
      detail: {
        spaceName: '空间名称:',
        spaceNamePlaceholder: '请输入空间名称',
        spaceNameError: '空间名称不能为空',
        floorTypeError: '请输入楼层类型',
        spaceCode: '空间编码:',
        spaceCodePlaceholder: '请输入空间编码',
        floorTypePlaceholder: '请输入楼层类型',
        spaceCodeError: '空间编码不能为空',
        area: '面积:',
        areaPlaceholder: '请输入面积',
        spaceType: '空间类型:',
        floorType: '楼层类型:',
        roomType: '房间类型:',
        equipmentRoomType: '房间类型:',
        orderNo: '排序',
        orderNoPlaceholder: '排序, 数字小的在上',
        parentId: '上级空间:',
        parentIdPlaceholder: '请选择上级空间'
      }
    },
    menus: {
      search: {
        title: '菜单名称：',
        titlePlaceholder: '请输入菜单名称'
      },
      table: {
        title: '菜单名称',
        path: '地址',
        orderNo: '排序',
        icon: '图标',
        menuType: '类型',
        enable: '状态',
        hidden: '隐藏',
        actionSetting: '按钮设置'
      },
      detail: {
        title: '名称：',
        titlePlaceholder: '菜单/按钮名称为zh.js文件中route/button中的key, 例：dashboard',
        titleError: '菜单名称必须',
        menuType: '菜单类型：',
        path: '菜单路径：',
        pathPlaceholder: '请输入菜单路径',
        pathError: '菜单路径必须',
        parentId: '父级菜单',
        component: '前端组件：',
        componentPlaceholder: '请输入前端组件',
        parentIdError: '前端组件必须',
        redirect: '跳转地址：',
        redirectPlaceholder: '请输入路由参数redirect',
        icon: '图标：',
        iconPlaceholder: '请输入菜单图标',
        orderNo: '排序：',
        orderNoPlaceholder: '请输入排序',
        hidden: '隐藏路由：',
        noCache: '缓存路由：',
        enable: '启用路由：'
      }
    }
  },
  code: {
    title: '设备列表',
    import: '导出表格',
    export: '新增设备',
    deviceName: '设备名称',
    inputDeviceName: '请输入设备名称',
    select: '请选择',
    deviceType: '设备类型',
    deviceAddress: '设备所在位置',
    deviceCompany: '设备所属公司',
    search: '搜索',
    index: '序号',
    deviceNumber: '设备编号',
    otherDeviceType: '其他设备类型',
    deviceBelongType: '设备归属类型',
    operation: '操作',
    lookDeviceDes: '查看设备信息详情',
    deviceModel: '设备推送模板',
    deviceUrl: '设备推送URL',
    edit: '编辑设备信息详情',
    building: '所属大楼',
    submit: '保存',
    cancel: '取消',
    addDevice: '添加设备'
  },
  personel: {
    title: '人员列表',
    createpersonel: '新增人员',
    userType: '人员类别',
    userName: '姓名',
    phoneNumber: '手机号',
    termOfValidity: '有效期',
    company: '相关公司-部门',
    lookUserDes: '查看人员信息详情',
    ID: '身份证号',
    UserCompany: '所属公司',
    department: '所属部门',
    visitCompany: '访问公司',
    face: '人脸照片',
    edit: '编辑设备信息详情',
    card: '卡信息表'
  },
  codeTrace: {
    title: '一码通追踪'
  },
  dept: {
    title: '部门权限管理',
    addtitle: '新增部门关联设备'
  },
  inactive: {
    title: '不活跃人员管理'
  },
  inspect: {
    title: '受检地址管理',
    addAddress: '新增地址',
    addPlan: '新增巡检计划'
  },
  equipmentSetatusManagement: {
    title: '设备状态管理'
  }
};