//
//
//
//

export default {
  name: 'Layout',
  data: function data() {
    return {
      // 重试次数
      reIndex: 0,
      auto: true
    };
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};