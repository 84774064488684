import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getWarnLevelsCountApi, fuzzyQueryWarnRecordApi } from '@/api/energySources/consumption/alarm';
import moment from 'moment';
export default {
  name: 'AppMain',
  directives: {
    drag: function drag(el) {
      // console.log('el', el)
      // 鼠标移动到目标盒子上--监听鼠标按下事件
      el.onmousedown = function (e) {
        var firstTime = null;
        var lastTime = null;
        document.getElementById('dragbtn').setAttribute('data-flag', false);
        firstTime = new Date().getTime();
        // console.log('e1', e)
        // 计算出此时点击目标盒子 相对于自己的左上角距离（目的是为了下方位移时候 赋值减去相应的自己左上角位置 保证盒子定位点还是自己点击的点 而不是左上角点）
        // vue项目对于原生的dom操作 其实就是在方法内 也就是js内 使用原生的方法对元素进行dom操作 和普通的js操作一样
        var floatDragDom = el.getBoundingClientRect();
        var disx = floatDragDom.width / 2;
        var disy = floatDragDom.height / 2;
        document.onmousemove = function (e2) {
          var move_box = document.getElementsByClassName('js-ball')[0];
          move_box.style.position = 'fixed';
          var l = e2.clientX - disx;
          var h = e2.clientY - disy;
          if (l > window.innerWidth - 110) {
            move_box.style.left = window.innerWidth - 110 + 'px';
          } else if (l < 0) {
            move_box.style.left = 0;
          } else {
            // console.log(l, disx)
            move_box.style.left = l + 'px';
          }
          if (h > window.innerHeight - 100) {
            move_box.style.top = window.innerHeight - 100 + 'px';
          } else if (h < 0) {
            move_box.style.top = 0;
          } else {
            move_box.style.top = h + 'px';
          }
          // move_box.style.left = e2.clientX - disx + 'px'
          // move_box.style.top = e2.clientY - disy + 'px'
          // console.log('最后的定位：', e2.clientX - disx, e2.clientY - disy, e2)
        };
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
          lastTime = new Date().getTime();
          if (lastTime - firstTime < 200) {
            document.getElementById('dragbtn').setAttribute('data-flag', true);
          } else {
            document.getElementById('dragbtn').setAttribute('data-flag', false);
          }
          return false;
        };
      };
    }
  },
  data: function data() {
    return {
      ballShow: false,
      detailShow: false,
      allCount: '',
      common: '',
      important: '',
      notice: '',
      serious: '',
      dialogFormVisible: false,
      alarmList: [],
      alarmList1: [],
      swruWarnLevelList: [{
        key: '0',
        label: '提示'
      }, {
        key: '1',
        label: '一般'
      }, {
        key: '2',
        label: '重要'
      }, {
        key: '3',
        label: '紧急'
      }] // 告警等级
    };
  },
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  },
  created: function created() {
    var _this = this;
    this.$eventHub.$on('ssdm_warn', function (notify) {
      if (notify) {
        _this.getInitData();
      }
    });
    this.$eventHub.$on('ssdm_normal', function (notify) {
      if (notify) {
        _this.getInitData();
      }
    });
    this.getInitData();
  },
  methods: {
    getInitData: function getInitData() {
      var _this2 = this;
      getWarnLevelsCountApi().then(function (res) {
        _this2.allCount = res.data.allCount;
        _this2.common = res.data.common;
        _this2.important = res.data.important;
        _this2.notice = res.data.notice;
        _this2.serious = res.data.serious;
        if (_this2.allCount > 0) {
          _this2.ballShow = true;
        } else {
          _this2.ballShow = false;
        }
      });
    },
    handelShow: function handelShow() {
      this.ballShow = !this.ballShow;
      this.detailShow = false;
    },
    handelDetail: function handelDetail() {
      var isClick = document.getElementById('dragbtn').getAttribute('data-flag');
      if (isClick !== 'true') {
        return false;
      }
      this.detailShow = true;
    },
    handelDetailShow: function handelDetailShow() {
      var isClick = document.getElementById('dragbtn').getAttribute('data-flag');
      if (isClick !== 'true') {
        return false;
      }
      this.dialogFormVisible = true;
      this.getAlarmInfoList();
    },
    getAlarmInfoList: function getAlarmInfoList() {
      var _this3 = this;
      var data = {
        currentPage: 1,
        pageSize: 100,
        swreIsWarn: '0'
      };
      fuzzyQueryWarnRecordApi(data).then(function (res) {
        _this3.alarmList = res.data.list;
        _this3.alarmList1 = res.data.list;
      });
    },
    handelClose: function handelClose() {
      this.dialogFormVisible = false;
      this.detailShow = false;
    },
    handelSkip: function handelSkip() {
      this.$router.push('/energyAlarm/realTimeAlarm');
      this.handelClose();
    },
    // 告警等级转义
    swruWarnLevelName: function swruWarnLevelName(val) {
      var name;
      for (var i = 0; i < this.swruWarnLevelList.length; i++) {
        if (this.swruWarnLevelList[i].key === val) {
          name = this.swruWarnLevelList[i].label;
          break;
        }
      }
      return name;
    },
    getTime: function getTime(val) {
      return moment(val).format('YYYY/MM/DD HH:mm:ss');
    },
    refresh: function refresh() {
      this.getInitData();
      this.getAlarmInfoList();
    },
    handleAll: function handleAll() {
      this.getAlarmInfoList();
    },
    handleLevel: function handleLevel(val) {
      var list = [];
      this.alarmList1.forEach(function (item) {
        if (item.nwreWarnLevel === val) {
          list.push(item);
        }
      });
      this.alarmList = list;
    }
  }
};