import "core-js/modules/es6.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  language: function language(state) {
    return state.app.language;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  buttons: function buttons(state) {
    return state.user.buttons;
  },
  deptList: function deptList(state) {
    return state.user.deptList;
  },
  userId: function userId(state) {
    return state.user.userId;
  },
  userType: function userType(state) {
    return state.user.userType;
  },
  deptId: function deptId(state) {
    return state.user.deptId;
  },
  companyName: function companyName(state) {
    return state.user.companyName;
  },
  mobile: function mobile(state) {
    return state.user.mobile;
  },
  email: function email(state) {
    return state.user.email;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  routeList: function routeList(state) {
    return state.permission.routeList;
  },
  addRoutes: function addRoutes(state) {
    return state.permission.addRoutes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  dataDic: function dataDic(state) {
    return state.baseApp.dataDic;
  },
  orgMap: function orgMap(state) {
    return state.baseApp.orgMap;
  },
  application: function application(state) {
    return state.baseApp.application;
  },
  userMap: function userMap(state) {
    return state.baseApp.userMap;
  },
  spaceMap: function spaceMap(state) {
    return state.baseApp.spaceMap;
  },
  weather: function weather(state) {
    return state.baseApp.weather;
  },
  buildId: function buildId(state) {
    return state.user.buildId;
  },
  companyId: function companyId(state) {
    return state.user.companyId;
  },
  scanUrl: function scanUrl(state) {
    return state.app.scanUrl;
  },
  zoneSchemaMap: function zoneSchemaMap(state) {
    return state.dragger.ZoneSchemaMap;
  },
  topic: function topic(state) {
    return state.user.topic;
  },
  jsTopic: function jsTopic(state) {
    return state.user.topic;
  }
  // shrBuildId: state => state.user.shrBuildId
};
export default getters;