import request from '@/utils/request';
export function getUserListApi(data) {
  return request({
    url: '/base/user/getUserByPage',
    method: 'post',
    data: data
  });
}
export function addUserApi(data) {
  return request({
    url: '/base/user/addUser',
    method: 'post',
    data: data
  });
}
export function deleteUserApi(params) {
  return request({
    url: '/base/user/deleteUser',
    method: 'post',
    params: params
  });
}
export function deleteUserFromOrgApi(params) {
  return request({
    url: '/base/org/deleteUserFromOrg',
    method: 'get',
    params: params
  });
}
export function getUserByIdApi(params) {
  return request({
    url: '/base/user/getUserById',
    method: 'post',
    params: params
  });
}
export function updateUserApi(data) {
  return request({
    url: '/base/user/updateUser',
    method: 'post',
    data: data
  });
}
export function checkPhoneApi(params) {
  return request({
    url: '/base/user/checkPhone',
    method: 'post',
    params: params
  });
}
export function checkMailApi(params) {
  return request({
    url: '/base/user/checkMail',
    method: 'post',
    params: params
  });
}
export function getUserMapApi() {
  return request({
    url: '/base/user/getUserMap',
    method: 'get'
  });
}
export function getAllUserListApi(data) {
  return request({
    url: '/base/user/getAllUserList',
    method: 'post',
    data: data
  });
}
export function getInspectPersonApi() {
  return request({
    url: '/inspect/taskAndPerson/getInspectPerson',
    method: 'get'
  });
}
export function updateRoleByUserIdApi(data) {
  return request({
    url: '/base/user/updateRoleByUserId',
    method: 'post',
    data: data
  });
}
export function getOrgIdListByUserApi(params) {
  return request({
    url: '/base/user/getOrgIdListByUser',
    method: 'get',
    params: params
  });
}
export function getRoleListByOrgIdAndUserApi(data) {
  return request({
    url: '/base/user/getRoleListByOrgIdAndUser',
    method: 'post',
    data: data
  });
}
export function refreshAuthApi(params) {
  return request({
    url: '/base/user/refreshAuth',
    method: 'post',
    params: params
  });
}
export function loginByPasswordApi(data) {
  return request({
    url: '/base/user/loginByPassword',
    method: 'post',
    data: data
  });
}

/**
 * 单点登录
 */
export function authenticationApi(params) {
  return request({
    url: '/ssdm/no-auth/user-org-manage/authentication',
    method: 'get',
    params: params
  });
}
export function loginByWeChatApi(params) {
  return request({
    url: '/base/user/loginByWeChat',
    method: 'post',
    params: params
  });
}
export function getUserInfoApi() {
  return request({
    url: '/base/user/getUserInfo',
    method: 'post'
  });
}
// // 污水
// export function getDataByOrgIdAndShrNameApi(data) {
//   return request({
//     url: '/stm/stHospitalRelation/getDataByOrgIdAndShrName',
//     method: 'post',
//     data: data
//   })
// }

export function logoutApi() {
  return request({
    url: '/base/user/logout',
    method: 'post'
  });
}
export function changeDeptApi(params) {
  return request({
    url: '/base/user/changeDept',
    method: 'post',
    params: params
  });
}
export function getUserByOrgIdApi(params) {
  return request({
    url: '/base/user/getUserByOrgId',
    method: 'post',
    params: params
  });
}
export function updateUserPwdApi(data) {
  return request({
    url: '/base/user/updateUserPwd',
    method: 'post',
    data: data
  });
}
export function sendCodeApi(params) {
  return request({
    url: '/base/user/sendCode',
    method: 'post',
    params: params
  });
}
export function loginByCodeApi(params) {
  return request({
    url: '/base/user/loginByCode',
    method: 'post',
    params: params
  });
}

// topic
export function getTopicApi(params) {
  return request({
    url: '/em/mansionTopic/getTopic',
    method: 'get',
    params: params
  });
}
// 久事topic
export function jsGetTopicApi(params) {
  return request({
    url: '/ssdm/mansionTopic/getTopic',
    method: 'get',
    params: params
  });
}
//  登录

export function getAuthenticationApi(params) {
  return request({
    url: '/base/no-auth/one-access/authentication',
    method: 'get',
    params: params
  });
}
//  查询工程安保人员IDList
export function getGroupByPeopleTypeApi() {
  return request({
    url: '/ers/peopleManagement/getGroupByPeopleType',
    method: 'get'
  });
}
//  更新工程安保人员
export function updateGroupByPeopleTypeApi(data) {
  return request({
    url: '/ers/peopleManagement/updateGroupByPeopleType',
    method: 'post',
    data: data
  });
}
//  根据权限获取首页定制
export function getIndexAddrApi() {
  return request({
    url: '/base/buildCustom/getIndexAddr',
    method: 'get'
  });
}
//  忘记密码
export function forgetUserPwdApi(data) {
  return request({
    url: '/base/user/no-auth/forgetUserPwd',
    method: 'post',
    data: data
  });
}