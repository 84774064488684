import { select, update, insert, remove, selectSchemata, selectColumnByTable } from '@/api/developer/sql';
import { Message } from 'element-ui';
var user = {
  actions: {
    selectSql: function selectSql(_ref, data) {
      var commit = _ref.commit;
      return new Promise(function (resolve, reject) {
        select(data).then(function (response) {
          if (response.code === 0) {
            Message.success(response.msg);
          } else {
            Message.error(response.msg);
          }
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    updateSql: function updateSql(_ref2, data) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        update(data).then(function (response) {
          if (response.code === 0) {
            Message.success(response.msg);
          } else {
            Message.error(response.msg);
          }
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    insertSql: function insertSql(_ref3, data) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        insert(data).then(function (response) {
          if (response.code === 0) {
            Message.success(response.msg);
          } else {
            Message.error(response.msg);
          }
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    deleteSql: function deleteSql(_ref4, data) {
      var commit = _ref4.commit;
      return new Promise(function (resolve, reject) {
        remove(data).then(function (response) {
          if (response.code === 0) {
            Message.success(response.msg);
          } else {
            Message.error(response.msg);
          }
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    getSchemata: function getSchemata(_ref5, data) {
      var commit = _ref5.commit;
      return new Promise(function (resolve, reject) {
        selectSchemata(data).then(function (response) {
          if (response.code !== 0) {
            Message.error(response.msg);
          }
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    getColumnByTable: function getColumnByTable(_ref6, data) {
      var commit = _ref6.commit;
      return new Promise(function (resolve, reject) {
        selectColumnByTable(data).then(function (response) {
          if (response.code !== 0) {
            Message.error(response.msg);
          }
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    }
  }
};
export default user;