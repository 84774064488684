var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ny-sidebar" },
    [
      _c(
        "el-menu",
        {
          attrs: {
            "default-active": _vm.activeMenu,
            collapse: _vm.isCollapse,
            "text-color": "#777777",
            "unique-opened": false,
            "active-text-color": "#006DFF",
            "collapse-transition": false,
            mode: "vertical",
          },
        },
        _vm._l(_vm.routerList, function (route) {
          return _c("sidebar-item", {
            key: route.path,
            attrs: { item: route, "base-path": route.path },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }