import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
var TokenKey = 'Admin-Token';
var AutToken = 'Aut-Token';
var Topic = 'topic';
// const JsTopic = 'jsTopic'
var OpenId = 'openId';
var AppId = 'appId';
var WxAppId = 'wxAppId';
export function getToken() {
  return sessionStorage.getItem(TokenKey);
}
export function getAutToken() {
  return sessionStorage.getItem(AutToken);
}
export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token);
}
export function setAutToken(token) {
  return sessionStorage.setItem(AutToken, token);
}
export function removeToken() {
  return sessionStorage.removeItem(TokenKey);
}
export function getTopic() {
  return sessionStorage.getItem(Topic);
}
export function setTopic(topic) {
  return sessionStorage.setItem(Topic, topic);
}
export function removeTopic() {
  return sessionStorage.removeItem(Topic);
}
export function getJsTopic() {
  return sessionStorage.getItem(Topic);
}
export function setJsTopic(topic) {
  return sessionStorage.setItem(Topic, topic);
}
export function removeJsTopic() {
  return sessionStorage.removeItem(Topic);
}
export function getAppId() {
  return sessionStorage.getItem(AppId);
}
export function setAppId(appId) {
  return sessionStorage.setItem(AppId, appId);
}
export function removeAppId() {
  return sessionStorage.removeItem(AppId);
}
export function getOpenId() {
  return sessionStorage.getItem(OpenId);
}
export function setOpenId(openId) {
  return sessionStorage.setItem(OpenId, openId);
}
export function removeOpenId() {
  return sessionStorage.removeItem(OpenId);
}
export function setWxAppId(appId) {
  return sessionStorage.setItem(WxAppId, appId);
}
export function removeWxAppId() {
  return sessionStorage.removeItem(WxAppId);
}

// 数组去重
export function array_remove_repeat(a) {
  var r = [];
  for (var i = 0; i < a.length; i++) {
    var flag = true;
    var temp = a[i];
    for (var j = 0; j < r.length; j++) {
      if (temp === r[j]) {
        flag = false;
        break;
      }
    }
    if (flag) {
      r.push(temp);
    }
  }
  return r;
}

// 清除空数组
export function clearEmptyArray(arr, prop) {
  arr.forEach(function (item) {
    if (!item[prop] || item[prop].length === 0) {
      item[prop] = null;
    } else {
      clearEmptyArray(item[prop], prop);
    }
  });
}

// 获取数组数据
export function getArrByIndex(item, index) {
  if (item instanceof Array) {
    if (item.length > 0) {
      return item[index];
    } else {
      return '';
    }
  } else if (item instanceof String) {
    return item;
  } else {
    return '';
  }
}

// 获取当前时间差
export function getTimeDiff(date) {
  var now = new Date();
  var old = new Date(date.replace(/-/g, '/'));
  var diff = now.getTime() - old.getTime();
  var year = Math.floor(diff / (24 * 3600 * 1000 * 30 * 12));
  var mouth = Math.floor(diff / (24 * 3600 * 1000 * 30));
  var day = Math.floor(diff / (24 * 3600 * 1000));
  var hour = Math.floor(diff / (60 * 1000 * 60));
  var minutes = Math.floor(diff / (60 * 1000));
  if (year > 0) {
    return year + '年前';
  } else if (mouth > 0) {
    return mouth + '个月前';
  } else if (day > 0) {
    return day + '天前';
  } else if (hour > 0) {
    return hour + '小时前';
  } else if (minutes > 5) {
    return minutes + '分钟前';
  } else if (minutes <= 5) {
    return '刚刚';
  }
}

// 手机号脱敏
export function phoneDesensitization(num) {
  var len = num.length;
  if (len && len > 4) {
    return num.substring(0, 3) + '****' + num.substring(len - 4, len);
  }
}