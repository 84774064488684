import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import store from '@/store';
export default {
  name: 'App',
  data: function data() {
    return {
      routerList: ['/monitor/administration', '/queryData/QueryData', '/queryData/periodicMonitoring', '/queryEnergy/generalSituation', '/queryEnergy/runningTime', '/queryCaution/overrunTime', '/queryCaution/malfunctionTime', '/queryCaution/equipmentAlarm', '/queryCaution/QueryCaution', '/statement/artifact', '/statement/statement', '/backSetting/warningSetting', '/backSetting/informationSetting']
    };
  },
  created: function created() {
    console.log(this.$route);
    store.dispatch('app/setScanUrl', location.href.split('#')[0]);
  }
};