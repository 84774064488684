import request from '@/utils/request';
export function getRoleListApi(data) {
  return request({
    url: '/base/role/getRoleByPage',
    method: 'post',
    data: data
  });
}
export function addRoleApi(data) {
  return request({
    url: '/base/role/addRole',
    method: 'post',
    data: data
  });
}
export function deleteRoleApi(params) {
  return request({
    url: '/base/role/deleteRole',
    method: 'post',
    params: params
  });
}
export function updateRoleApi(data) {
  return request({
    url: '/base/role/updateRole',
    method: 'post',
    data: data
  });
}
export function updateRole2menusApi(data) {
  return request({
    url: '/base/role/updateRole2menus',
    method: 'post',
    data: data
  });
}
export function getRole2menusApi(params) {
  return request({
    url: '/base/role/getRole2menus',
    method: 'get',
    params: params
  });
}
export function getMenusIdByRoleIdApi(params) {
  return request({
    url: '/base/role/getMenusIdByRoleId',
    method: 'get',
    params: params
  });
}
export function getAllMenusTreeByRoleIdApi(params) {
  return request({
    url: '/base/role/getAllMenusTreeByRoleId',
    method: 'get',
    params: params
  });
}
export function getAllRoleListApi() {
  return request({
    url: '/base/role/getAllRoleList',
    method: 'post'
  });
}

// 查看角色
export function getUserListByRoleApi(data) {
  return request({
    url: '/base/role/getUserListByRole',
    method: 'post',
    data: data
  });
}

// 下载角色信息
export function exportRolesUser(data) {
  return request({
    url: '/base/role/exportRolesUser',
    method: 'post',
    data: data
  });
}

// 根据当前页面menusId,查询当前用户下的授权按钮列表
export function selectButtonWithAuthApi(params) {
  return request({
    url: '/base/role/selectButtonWithAuth',
    method: 'get',
    params: params
  });
}