import "core-js/modules/es6.regexp.split";
export function checkOS() {
  var result = 'pc';
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    // 判断iPhone|iPad|iPod|iOS
    result = 'ios';
  } else if (/(Android)/i.test(navigator.userAgent)) {
    // 判断Android
    result = 'android';
  } else if (/(MicroMessenger)/i.test(navigator.userAgent)) {
    result = 'wx';
  }
  return result;
}

// 根据文件名后缀区分 文件类型
/*
 * @param: fileName - 文件名称
 * @param: 数据返回 1) 无后缀匹配 - false
 * @param: 数据返回 2) 匹配图片 - image
 * @param: 数据返回 3) 匹配 txt - txt
 * @param: 数据返回 4) 匹配 excel - excel
 * @param: 数据返回 5) 匹配 word - word
 * @param: 数据返回 6) 匹配 pdf - pdf
 * @param: 数据返回 7) 匹配 ppt - ppt
 * @param: 数据返回 8) 匹配 视频 - video
 * @param: 数据返回 9) 匹配 音频 - radio
 * @param: 数据返回 10) 其他匹配项 - other
 */
export function matchType(fileName) {
  // 后缀获取
  var suffix = '';
  // 获取类型结果
  var result = '';
  try {
    var fileArr = fileName.split('.');
    suffix = fileArr[fileArr.length - 1];
  } catch (err) {
    suffix = '';
  }
  // fileName无后缀返回 false
  if (!suffix) {
    result = false;
    return result;
  }
  // 图片格式
  var imgList = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
  // 进行图片匹配
  result = imgList.some(function (item) {
    return item === suffix;
  });
  if (result) {
    result = 'image';
    return result;
  }
  // 匹配txt
  var txtList = ['txt'];
  result = txtList.some(function (item) {
    return item === suffix;
  });
  if (result) {
    result = 'txt';
    return result;
  }
  // 匹配 excel
  var excelList = ['xls', 'xlsx'];
  result = excelList.some(function (item) {
    return item === suffix;
  });
  if (result) {
    result = 'excel';
    return result;
  }
  // 匹配 word
  var wordList = ['doc', 'docx'];
  result = wordList.some(function (item) {
    return item === suffix;
  });
  if (result) {
    result = 'word';
    return result;
  }
  // 匹配 pdf
  var pdfList = ['pdf'];
  result = pdfList.some(function (item) {
    return item === suffix;
  });
  if (result) {
    result = 'pdf';
    return result;
  }
  // 匹配 ppt
  var pptList = ['ppt', 'pptx'];
  result = pptList.some(function (item) {
    return item === suffix;
  });
  if (result) {
    result = 'ppt';
    return result;
  }
  // 匹配 视频
  var videoList = ['mp4', 'm2v', 'mkv'];
  result = videoList.some(function (item) {
    return item === suffix;
  });
  if (result) {
    result = 'video';
    return result;
  }
  // 匹配 音频
  var radioList = ['mp3', 'wav', 'wmv'];
  result = radioList.some(function (item) {
    return item === suffix;
  });
  if (result) {
    result = 'radio';
    return result;
  }
  // 其他 文件类型
  result = 'other';
  return result;
}
export function findFunc(permissionsList, LimitData, key, setChecked) {
  // 退出递归标志
  var isExit = false;
  for (var j = 0; j < LimitData.length; j++) {
    if (LimitData[j].children && LimitData[j].children.length > 0) {
      // 如果有children，那么说明它是父级，此时不将他加入setChecked
      isExit = false;
      findFunc(permissionsList, LimitData[j].children, key, setChecked);
    } else {
      // 当它是子级时再进行匹配
      for (var i = 0; i < permissionsList.length; i++) {
        if (LimitData[j][key] === permissionsList[i]) {
          // 菜单权限匹配
          setChecked.push(LimitData[j][key]);
        } else {
          // 如果是子级，且没有匹配成功，则设置标识
          isExit = true;
        }
      }
    }
  }
  if (isExit) {
    // 循环完成后进行判断
    return false;
  }
}
export function copyText(text) {
  var aux = document.createElement('input');
  aux.setAttribute('value', text);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand('copy');
  document.body.removeChild(aux);
}

/**
 * 自定义函数名：prefixZero
 * @param num： 被操作数
 * @param n： 固定的总位数
 * @param fix： 补位的内容
 */
export function prefixStr(num, n, fix) {
  return (Array(n).join(fix) + num).slice(-n);
}
export function getQueryVariable(query, variable) {
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

/**
 * 将秒转换为 分:秒
 * s int 秒数
 */
export function s_to_hs(s) {
  // 计算分钟
  // 算法：将秒数除以60，然后下舍入，既得到分钟数
  var h;
  h = Math.floor(s / 60);
  // 计算秒
  // 算法：取得秒%60的余数，既得到秒数
  s = s % 60;
  // 将变量转换为字符串
  h += '';
  s += '';
  // 如果只有一位数，前面增加一个0
  h = h.length === 1 ? '0' + h : h;
  s = s.length === 1 ? '0' + s : s;
  return h + ':' + s;
}
export function getRandom(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}