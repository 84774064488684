import "core-js/modules/es7.object.get-own-property-descriptors";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/app/node_modules/core-js/modules/es6.array.iterator.js";
import "/app/node_modules/core-js/modules/es6.promise.js";
import "/app/node_modules/core-js/modules/es6.object.assign.js";
import "/app/node_modules/core-js/modules/es7.promise.finally.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Vue from 'vue';
import * as lodash from 'lodash';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import _i18n from "./lang"; // internationalization
import "./icons"; // icon
import "./permission"; // permission control
import "./utils/error-log"; // error log
// 引入字体
import "./font/fonts.css";
import * as filters from "./filters"; // global filters
import Vant from 'vant';
import 'vant/lib/index.css';
// import 'default-passive-events'      //暂时注释引用

import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Vue.use(Viewer);

// 引入 Konva
import VueKonva from 'vue-konva';
Vue.use(VueKonva);

// 引入vue-lottie
import lottie from 'vue-lottie';
Vue.component('lottie', lottie);
import moment from 'moment';
Vue.prototype.$moment = moment;
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
Vue.use(mavonEditor);

// 页面转PDF
import htmlToPdf from '@/utils/htmlToPdf';
Vue.use(htmlToPdf);
import MyCascader from '@/components/MyComponents';
Vue.component('MyCascader', MyCascader);
// 引入地图
import VueAMap from 'vue-amap';
import { getToken } from '@/utils/auth';
import { checkOS } from '@/utils/system';
import { formatDate } from '@/utils/date';

// 引入table滚动
import elTableInfiniteScroll from 'el-table-infinite-scroll';
Vue.use(elTableInfiniteScroll);

// 引入buildId
import { mapGetters } from 'vuex';

// Vue.use(Speech)

Vue.use(VueAMap);
// 初始化
window._AMapSecurityConfig = {
  // 设置安全密钥
  securityJsCode: '056717d553e975f008bcb930e3fd1114'
};
VueAMap.initAMapApiLoader({
  AMap: 'Amap',
  key: '4954c001eb3a4af9283fe60f1e0215ab',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation'],
  v: '1.4.15',
  uiVersion: '1.0',
  mapStyle: 'amap://styles/darkblue'
});
Viewer.setDefaults({
  Options: {
    'inline': true,
    'button': true,
    'navbar': true,
    'title': true,
    'toolbar': true,
    'tooltip': true,
    'movable': true,
    'zoomable': true,
    'rotatable': true,
    'scalable': true,
    'transition': true,
    'fullscreen': true,
    'keyboard': true,
    'url': 'data-source'
  }
});
Vue.use(Vant);
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  size: Cookies.get('size') || 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  },
  zIndex: 1000
});
Vue.prototype.$lodash = lodash;
// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.prototype.$image = function (filename, src) {
  if (!src) {
    src = 'path=/home/httpd/FM/resources/web-image';
  }
  return process.env.VUE_APP_BASE_API + '/util/file/preview?name=' + filename + '&' + src;
};
Vue.config.productionTip = false;
Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue({
  i18n: _i18n
});
new Vue({
  el: '#app',
  data: function data() {
    return {
      ws: null,
      websocketUri: ''
    };
  },
  computed: _objectSpread({}, mapGetters(['buildId'])),
  created: function created() {
    Vue.prototype.$ws = {
      handleCon: this.handleCon
    };
    this.websocketUri = process.env.VUE_APP_SOCKET_API;
  },
  mounted: function mounted() {
    this.handleCon();
    setInterval(this.heartbeat, 240000);
  },
  beforeDestroy: function beforeDestroy() {
    if (this.ws.readyState === this.ws.OPEN) {
      this.ws.close();
    }
  },
  methods: {
    heartbeat: function heartbeat() {
      if (this.ws && this.ws.readyState === this.ws.OPEN) {
        console.log('保持连接...');
        this.ws.send('保持连接...');
      }
    },
    handleCon: function handleCon() {
      if (getToken()) {
        this.ws = new WebSocket(this.websocketUri + '/message/' + getToken());
        this.ws.onopen = this.wsOnopen;
        this.ws.onmessage = this.wsOnmessage;
        // 连接关闭的回调方法
        this.ws.onclose = this.wsClose;
        this.ws.onerror = this.wsError;
      }
    },
    wsOnmessage: function wsOnmessage(event) {
      // const _this = this
      var notify = JSON.parse(event.data);
      if (checkOS() === 'pc') {
        notify.duration = 0;
        // notify.onClick = function goFm() {
        //   _this.$router.push('/dailyManage/index')
        // }
      }
      notify.duration = 30000;
      // console.log(notify, 'mess')
      switch (notify.source) {
        case 'task':
          this.$notify(notify);
          this.$eventHub.$emit('refresh');
          break;
        case 'parking':
          this.$eventHub.$emit('parking', notify);
          break;
        case 'fire':
          this.$eventHub.$emit('fire', notify);
          break;
        case 'window':
          this.$eventHub.$emit('window', notify);
          break;
        case 'FireLiveTimeData':
          this.$eventHub.$emit('fireLiveTimeData', notify);
          break;
        case 'WelinkFireLiveTimeData':
          this.$eventHub.$emit('welinkFireLiveTimeData', notify);
          break;
        case 'realTimeMonitoringDataST':
          this.$eventHub.$emit('realTimeMonitoringDataST', notify);
          break;
        case 'dlg_fm_fire':
          this.$eventHub.$emit('dlg_fm_fire', notify);
          break;
        case 'ssdm_warn':
          this.$eventHub.$emit('ssdm_warn', notify);
          break;
        case 'ssdm_normal':
          this.$eventHub.$emit(' ssdm_normal', notify);
          break;
        case 'zifi':
          {
            var device = JSON.parse(notify.message);
            // device.dataDetail = JSON.parse(device.dataDetail)
            // console.log(device)
            this.$eventHub.$emit('ziFi', device);
            break;
          }
        // case 'fsdg': {
        //   const device = notify.message
        //   // device.dataDetail = JSON.parse(device.dataDetail)
        //   // console.log(device)
        //   this.$eventHub.$emit('fsdg', device)
        //   break
        // }
        case 'bac':
          {
            this.$eventHub.$emit('bacTime', JSON.parse(notify.message));
            break;
          }
        case 'light':
          {
            this.$eventHub.$emit('light', notify);
            break;
          }
        case 'downloadEquQR':
          {
            this.$eventHub.$emit('downloadEquQR', JSON.parse(notify.message));
            break;
          }
        case 'fcu':
          {
            this.$eventHub.$emit('fcuIcu5000', JSON.parse(notify.message));
            break;
          }
        case 'fire-ruiyan':
          {
            this.$eventHub.$emit('fire-ruiyan', notify.message);
            // console.log('111111----------------',notify.message)
            break;
          }
        case 'ssdg':
          {
            this.$eventHub.$emit('ssdg', notify.message);
            break;
          }
        case 'STSimplePlatformData':
          {
            this.$eventHub.$emit('STSimplePlatformData', notify.message);
            break;
          }
      }
      switch (notify.type) {
        case 'RealTimeChBaseData':
          {
            this.$eventHub.$emit('RealTimeChBaseData', JSON.parse(notify.message));
            break;
          }
        case 'RealTimeChClassifiedData':
          {
            this.$eventHub.$emit('RealTimeChClassifiedData', JSON.parse(notify.message));
            break;
          }
      }
      if (notify.source === this.buildId) {
        this.$eventHub.$emit(this.buildId, notify);
      }
    },
    wsOnopen: function wsOnopen() {
      this.reIndex = 0;
      console.log('已连接...' + formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'));
    },
    wsClose: function wsClose() {
      console.log('已关闭...' + formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'));
      if (this.reIndex++ < 3) {
        console.log('连接已断开，重试连接(' + this.reIndex + ')...' + formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'));
        setTimeout(this.handleCon, 5000);
      } else {
        console.log('连接失败，网络异常' + formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'));
      }
    },
    wsError: function wsError() {
      console.log('连接出错...');
    }
  },
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});