var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.routerList.includes(_vm.$route.fullPath.split("/")[1])
      ? _c(
          "div",
          {
            staticClass: "app-wrapper",
            class: _vm.classObj,
            style: _vm.innerWidth < 1100 ? "zoom:0.75" : "",
          },
          [
            _vm.checkOS() !== "pc" && _vm.sidebar.opened
              ? _c("div", {
                  staticClass: "drawer-bg",
                  on: { click: _vm.handleClickOutside },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("sidebar", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isFull,
                  expression: "!isFull",
                },
              ],
              staticClass: "sidebar-container",
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "main-container",
                class: { hasTagsView: _vm.needTagsView },
                style: _vm.isFull ? { marginLeft: "0" } : {},
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isFull,
                        expression: "!isFull",
                      },
                    ],
                    class: { "fixed-header": _vm.fixedHeader && !_vm.isFull },
                  },
                  [
                    _c("navbar"),
                    _vm._v(" "),
                    _vm.needTagsView ? _c("tags-view") : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("app-main"),
                _vm._v(" "),
                _vm.showSettings
                  ? _c(
                      "right-panel",
                      { attrs: { "button-top": 85 } },
                      [_c("settings")],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.routerList.includes(_vm.$route.fullPath.split("/")[1]) &&
    _vm.$route.fullPath.split("/")[1] != "energy-sources"
      ? _c("div", { staticClass: "app-js-wrapper openSidebar" }, [
          _c("div", { staticClass: "app-js-header" }, [_c("js-header")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "app-js-main" }, [
            _c(
              "div",
              {
                staticClass: "app-js-aside",
                style: [
                  { width: !_vm.isCollapse ? "15%" : "0" },
                  {
                    border: !_vm.isCollapse
                      ? "border: 1px solid #eaebf0;"
                      : "none",
                  },
                ],
              },
              [_c("js-sidebar")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "app-js-conent",
                style: [{ width: !_vm.isCollapse ? "85%" : "100%" }],
              },
              [_c("js-app-main")],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$route.fullPath.split("/")[1] === "energy-sources"
      ? _c("div", { staticClass: "app-js-wrapper openSidebar" }, [
          _c("div", { staticClass: "app-js-header" }, [_c("energy-header")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "app-js-main" }, [
            _c(
              "div",
              {
                staticClass: "app-js-aside",
                style: [
                  { width: !_vm.isCollapse ? "15%" : "0" },
                  {
                    border: !_vm.isCollapse
                      ? "border: 1px solid #eaebf0;"
                      : "none",
                  },
                ],
              },
              [_c("ny-sidebar")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "app-js-conent",
                style: [{ width: !_vm.isCollapse ? "85%" : "100%" }],
              },
              [_c("energy-app-main")],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }