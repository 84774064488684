import request from '@/utils/request';
export function getDataDicListApi(data) {
  return request({
    url: '/base/dataDic/getDataDicByPage',
    method: 'post',
    data: data
  });
}
export function addDataDicApi(data) {
  return request({
    url: '/base/dataDic/addDataDic',
    method: 'post',
    data: data
  });
}
export function deleteDataDicApi(params) {
  return request({
    url: '/base/dataDic/deleteDataDic',
    method: 'post',
    params: params
  });
}
export function updateDataDicApi(data) {
  return request({
    url: '/base/dataDic/updateDataDic',
    method: 'post',
    data: data
  });
}
export function getDataDicDetailListApi(data) {
  return request({
    url: '/base/dataDicDetail/getDataDicDetailByPage',
    method: 'post',
    data: data
  });
}
export function addDataDicDetailApi(data) {
  return request({
    url: '/base/dataDicDetail/addDataDicDetail',
    method: 'post',
    data: data
  });
}
export function updateDataDicDetailApi(data) {
  return request({
    url: '/base/dataDicDetail/updateDataDicDetail',
    method: 'post',
    data: data
  });
}
export function deleteDataDicDetailApi(params) {
  return request({
    url: '/base/dataDicDetail/deleteDataDicDetail',
    method: 'post',
    params: params
  });
}
export function getDataDicTreeApi() {
  return request({
    url: '/base/dataDic/getDataDicTree',
    method: 'get'
  });
}