//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { checkOS } from '@/utils/system';
import { Notify } from 'vant';
export default {
  computed: {
    language: function language() {
      return this.$store.getters.language;
    }
  },
  methods: {
    handleSetLanguage: function handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('app/setLanguage', lang);
      if (checkOS() === 'pc') {
        this.$message({
          message: this.$t('tips.sls'),
          type: 'success'
        });
      } else {
        Notify({
          type: 'success',
          message: this.$t('tips.sls')
        });
      }
    }
  }
};