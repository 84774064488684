import request from '@/utils/request';
export function getOrgTreeApi() {
  return request({
    url: '/base/org/getOrgTree',
    method: 'get'
  });
}
export function addOrgApi(data) {
  return request({
    url: '/base/org/addOrg',
    method: 'post',
    data: data
  });
}
export function deleteOrgApi(params) {
  return request({
    url: '/base/org/deleteOrg',
    method: 'post',
    params: params
  });
}
export function selectOrgById(params) {
  return request({
    url: '/base/org/selectOrgById',
    method: 'get',
    params: params
  });
}
export function updateOrgApi(data) {
  return request({
    url: '/base/org/updateOrg',
    method: 'post',
    data: data
  });
}
export function getOrgMapApi() {
  return request({
    url: '/base/org/getOrgMap',
    method: 'get'
  });
}
export function getRoleByOrgIdApi(params) {
  return request({
    url: '/base/org/getRoleByOrgId',
    method: 'get',
    params: params
  });
}
export function updateRoleByOrgIdApi(data) {
  return request({
    url: '/base/org/updateRoleByOrgId',
    method: 'post',
    data: data
  });
}

// 公司接口
export function getCompanyListApi() {
  return request({
    url: '/base/org/getCompanyList',
    method: 'get'
  });
}

// 所有公司接口
export function getAllCompanyListApi() {
  return request({
    url: '/base/org/getAllCompanyList',
    method: 'get'
  });
}
export function reissueInviteApi(id) {
  return request({
    url: "/visitor/visitor/reissueInvite?visitId=".concat(id),
    method: 'get'
  });
}