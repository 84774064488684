import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//

import screenfull from 'screenfull';
import { mapState } from 'vuex';
export default {
  name: 'Screenfull',
  data: function data() {
    return {
      isFullscreen: false
    };
  },
  computed: _objectSpread({}, mapState({
    isFull: function isFull(state) {
      return state.settings.isFull;
    }
  })),
  mounted: function mounted() {
    this.init();
  },
  beforeDestroy: function beforeDestroy() {
    this.destroy();
  },
  methods: {
    click: function click() {
      if (!screenfull.enabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning'
        });
        return false;
      }
      screenfull.toggle();
    },
    change: function change() {
      this.isFullscreen = screenfull.isFullscreen;
      console.log(this.isFullscreen);
      this.$store.dispatch('settings/changeSetting', {
        key: 'isFull',
        value: this.isFullscreen
      });
    },
    init: function init() {
      if (screenfull.enabled) {
        screenfull.on('change', this.change);
      }
    },
    destroy: function destroy() {
      if (screenfull.enabled) {
        screenfull.off('change', this.change);
      }
    }
  }
};