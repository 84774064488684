// 员工天地-模板设计-页面ZoneSchemaMap
var state = {
  zoneSchemaMap: {
    // 组件选择器
    selector: [],
    // 表单数据
    formData: {},
    // 表单属性配置
    schema: {
      type: 'object',
      requeired: [],
      properties: {
        foo: {
          type: 'string',
          title: 'demo',
          default: 'default foo'
        }
      }
    },
    // 表单属性刷新
    uiSchema: {},
    // 错误提示信息
    errorSchema: {},
    // 表单底部
    formFooter: {},
    // 表单属性
    formProps: {
      labelWidth: 25,
      inline: false,
      labelPosition: 'top',
      inlineFooter: false,
      layoutColumn: 1
    }
  }
};
var mutations = {
  updateElements: function updateElements(state, payload) {
    state.zoneSchemaMap.selector = payload;
  }
};
var actions = {
  updateElements: function updateElements(_ref, payload) {
    var commit = _ref.commit;
    commit('updateElements', payload);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};