import { getDataDicTreeApi } from '@/api/baseApp/data-dic';
import { getOrgMapApi } from '@/api/baseApp/org';
import { getUserMapApi } from '@/api/baseApp/user';
import { getUserApplicationApi } from '@/api/baseApp/application';
import { getSpaceMapApi } from '@/api/baseApp/space';
import { simpleWeatherApi } from '@/api/util/weather';
var state = {
  dataDic: {},
  orgMap: {},
  userMap: {},
  spaceMap: {},
  application: [],
  weather: null
};
var mutations = {
  SET_DATA_DIC: function SET_DATA_DIC(state, dataDic) {
    state.dataDic = dataDic;
  },
  SET_ORG_MAP: function SET_ORG_MAP(state, orgMap) {
    state.orgMap = orgMap;
  },
  SET_USER_MAP: function SET_USER_MAP(state, userMap) {
    state.userMap = userMap;
  },
  SET_APPLICATION: function SET_APPLICATION(state, application) {
    state.application = application;
  },
  SET_SPACE_MAP: function SET_SPACE_MAP(state, spaceMap) {
    state.spaceMap = spaceMap;
  },
  SET_WEATHER: function SET_WEATHER(state, weather) {
    state.weather = weather;
  }
};
var actions = {
  getDataDicTree: function getDataDicTree(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      getDataDicTreeApi().then(function (res) {
        var data = res.data;
        commit('SET_DATA_DIC', data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getOrgMap: function getOrgMap(_ref2) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      getOrgMapApi().then(function (res) {
        var data = res.data;
        commit('SET_ORG_MAP', data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getUserMap: function getUserMap(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      getUserMapApi().then(function (res) {
        var data = res.data;
        commit('SET_USER_MAP', data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getSpaceMap: function getSpaceMap(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve, reject) {
      getSpaceMapApi().then(function (res) {
        var data = res.data;
        commit('SET_SPACE_MAP', data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getUserApplication: function getUserApplication(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve, reject) {
      getUserApplicationApi().then(function (res) {
        var data = res.data;
        commit('SET_APPLICATION', data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getCityAndWea: function getCityAndWea(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve, reject) {
      if (sessionStorage.getItem('sewageLogin') === '1') {
        return;
      } else {
        simpleWeatherApi({
          city: '上海'
        }).then(function (res) {
          var data = res.data;
          commit('SET_WEATHER', data.realtime);
          resolve();
        }).catch(function (e) {
          reject(e);
        });
      }
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};