import request from '@/utils/request';
export function getSpaceTreeApi() {
  return request({
    url: '/base/space/getSpaceTree',
    method: 'get'
  });
}
export function addSpaceApi(data) {
  return request({
    url: '/base/space/addSpace',
    method: 'post',
    data: data
  });
}
export function deleteSpaceApi(params) {
  return request({
    url: '/base/space/deleteSpace',
    method: 'post',
    params: params
  });
}
export function updateSpaceApi(data) {
  return request({
    url: '/base/space/updateSpace',
    method: 'post',
    data: data
  });
}
export function getSpaceByTypeApi(data) {
  return request({
    url: '/base/space/getSpaceByType',
    method: 'post',
    data: data
  });
}
export function getSpaceMapApi() {
  return request({
    url: '/base/space/getSpaceMap',
    method: 'get'
  });
}
export function getFloorByUserApi() {
  return request({
    url: '/base/space/getFloorByUser',
    method: 'get'
  });
}
export function getRoomByRoomTypeApi(params) {
  return request({
    url: '/base/space/getRoomByRoomType',
    method: 'get',
    params: params
  });
}
export function getSpaceVoMapApi() {
  return request({
    url: '/base/space/getSpaceVoMap',
    method: 'get'
  });
}
export function getCompanyByFloorApi(data) {
  return request({
    url: '/base/space/getCompanyByFloor',
    method: 'post',
    data: data
  });
}

// companyId(store里面取值) spaceType = 1/2/3 (1楼宇,2区域,3楼层,4房间) floorId 楼层Id
export function getSpaceByCompanyApi(params) {
  return request({
    url: '/base/space/getSpaceByCompany',
    method: 'post',
    params: params
  });
}
export function getSpaceByCompanyListApi(data, params) {
  return request({
    url: '/base/space/getSpaceByCompanyList',
    method: 'post',
    data: data,
    params: params
  });
}
export function getSpaceListByParentIdApi(params) {
  return request({
    url: '/base/space/getSpaceListByParentId',
    method: 'get',
    params: params
  });
}

// 久事能源空间位置
export function getJsSpaceTreeApi(data) {
  return request({
    url: '/ssdm/dim-space-record-d/getSpaceTree',
    method: 'post',
    data: data
  });
}

// 久事能源空间可视化
export function updateSpaceVisibleApi(data) {
  return request({
    url: '/ssdm/dim-space-record-d/updateSpaceVisible',
    method: 'post',
    data: data
  });
}

// 久事能源空间可视化多选
export function updateSpaceVisibleListApi(data) {
  return request({
    url: '/ssdm/dim-space-record-d/updateSpaceVisibleList',
    method: 'post',
    data: data
  });
}

// 久事能源区域下仪表
export function getSpaceDevice(params) {
  return request({
    url: '/ssdm/in-service-device/getSpaceDevice',
    method: 'get',
    params: params
  });
}

// 久事能源空间可视化
export function getSpaceByIdApi(params) {
  return request({
    url: '/ssdm/dim-space-record-d/getSpaceById',
    method: 'get',
    params: params
  });
}