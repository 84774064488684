import "core-js/modules/es6.function.name";
import defaultSettings from '@/settings';
import i18n from '@/lang';
import { checkOS } from '@/utils/system';
export default function getPageTitle(value, key) {
  if (checkOS() === 'pc') {
    var title = value.name === 'sewageLogin' ? '医院污水处理监管平台' : defaultSettings.title || '楼宇智慧管理运营系统';
    var hasKey = i18n.te("route.".concat(key));
    if (hasKey) {
      var pageName = i18n.t("route.".concat(key));
      if (checkOS() === 'pc') {
        return "".concat(pageName);
      } else {
        return "".concat(pageName);
      }
    }
    return "".concat(title);
  } else {
    var sewageLogin = sessionStorage.getItem('sewageLogin');
    var _title = value.name === 'sewageLogin' || sewageLogin === '1' ? '青源云污水监测' : defaultSettings.title || '楼宇智慧管理运营系统';
    var _hasKey = i18n.te("route.".concat(key));
    if (_hasKey) {
      var _pageName = i18n.t("route.".concat(key));
      if (checkOS() === 'pc') {
        return "".concat(_pageName);
      } else {
        return "".concat(_pageName);
      }
    }
    return "".concat(_title);
  }
}