import request from '@/utils/request';

// 获取有效的展会信息列表
export function getValidExhibitionInfoListApi() {
  return request({
    url: 'ers/outside-service/no-auth/getValidExhibitionInfoList',
    method: 'get'
  });
}
// 获取当前展会下的所有展厅
export function getAllExhibitionHallInfoApi(data) {
  return request({
    url: 'ers/outside-service/no-auth/getAllExhibitionHallInfo',
    method: 'post',
    data: data
  });
}
// 获取已经选择的展会信息
export function getSelectedExhibitionInfoApi() {
  return request({
    url: 'ers/outside-service/no-auth/getSelectedExhibitionInfo',
    method: 'get'
  });
}
// 模糊查询展位信息
export function noAuthFuzzyQueryApi(data) {
  return request({
    url: 'ers/outside-service/no-auth/fuzzyQuery',
    method: 'post',
    data: data
  });
}
// 获取验证码（展商+主场搭建）
export function sendCodeApi(data) {
  return request({
    url: 'ers/outside-service/no-auth/sendCode',
    method: 'post',
    data: data
  });
}
// 登入获取token（展商）
export function loginApi(params) {
  return request({
    url: 'ers/outside-service/no-auth/login',
    method: 'get',
    params: params
  });
}
// （展商）获取整改通知单列表
export function getRectifyNoticeListApi() {
  return request({
    url: 'ers/outside-service/no-auth/exhibitor/getRectifyNoticeList',
    method: 'get'
  });
}
// （展商）获取整改通知单详细信息
export function getRectifyNoticeInfoByIdApi(params) {
  return request({
    url: 'ers/outside-service/no-auth/exhibitor/getRectifyNoticeInfoById',
    method: 'get',
    params: params
  });
}
// （展商）整改回复
export function getreplyRectifyApi(data) {
  return request({
    url: 'ers/outside-service/no-auth/exhibitor/replyRectify',
    method: 'post',
    data: data
  });
}
// 获取当前展会下的所有主场搭建
export function getAllOfficeConstructorApi() {
  return request({
    url: 'ers/outside-service/no-auth/getAllOfficeConstructor',
    method: 'get'
  });
}
// (主场搭建)获取当前主场搭建ID
export function getCurrentOfficeConstructorInfoApi() {
  return request({
    url: 'ers/outside-service/no-auth/officeConstructor/getCurrentOfficeConstructorInfo',
    method: 'get'
  });
}
// （展商）获取展位信息
export function selectBoothInfoApi() {
  return request({
    url: 'ers/outside-service/no-auth/exhibitor/selectBoothInfo',
    method: 'get'
  });
}