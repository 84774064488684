import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.replace";
export function formatDate(d, fmt) {
  if (!d) {
    return '';
  }
  var date = new Date(d);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}
function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}
export function isSameDay(timeStampA, timeStampB) {
  var dateA = new Date(timeStampA);
  var dateB = new Date(timeStampB);
  return dateA.setHours(0, 0, 0, 0) === dateB.setHours(0, 0, 0, 0);
}
var moneyStep = 5;
var timeStep = 60 * 1000 * 30;

/**
 * 停车场计算出场费用
 * @param all 总费用
 * @param start 入场时间
 * @param end 出场时间
 * @returns {number|*} 总费用
 */
export function calculationCarMoney(all, start, end) {
  if ((end.getTime() - start.getTime()) / timeStep < 0.5) return 0;
  var middle = new Date(start.getTime());
  middle.setDate(middle.getDate() + 1);
  middle.setHours(0, 0, 0, 0);
  if (middle.getTime() >= end.getTime()) {
    var money = Math.ceil((end.getTime() - start.getTime()) / timeStep) * moneyStep;
    if (money < 10) {
      money = all > 0 ? 5 : 10;
    } else if (money > 80) {
      money = 80;
    }
    all += money;
    return all;
  } else {
    var _money = Math.ceil((middle.getTime() - start.getTime()) / timeStep) * moneyStep;
    if (_money < 10) {
      _money = 10;
    } else if (_money > 80) {
      _money = 80;
    }
    all += _money;
    return calculationCarMoney(all, middle, end);
  }
}
export function strToDate(str) {
  return new Date(str.replace(/-/g, '/'));
}
export var daysToChina = {
  '1': '周一',
  '2': '周二',
  '3': '周三',
  '4': '周四',
  '5': '周五',
  '6': '周六',
  '0': '周日'
};