var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "js-navbar" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "logo" }, [
        _vm.topic === "THDS"
          ? _c("img", {
              attrs: {
                src: require("@/assets/ny-img/th-logo.png"),
                width: "130px",
                alt: "",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.topic === "ZHGC"
          ? _c("img", {
              attrs: {
                src: require("@/assets/ny-img/ZHGC.png"),
                width: "130px",
                alt: "",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.topic === "ZX"
          ? _c("img", {
              attrs: {
                src: require("@/assets/ny-img/ZX-logo.png"),
                width: "170px",
                alt: "",
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "setting" }, [
        _c("div", { staticClass: "time" }, [
          _c("div", { staticClass: "date" }, [
            _vm._v("\n          " + _vm._s(_vm.nowTime) + "\n        "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "datatime" }, [
            _vm._v("\n          " + _vm._s(_vm.newTime) + "\n        "),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "userinfo" },
          [
            _c(
              "el-dropdown",
              {
                staticClass: "avatar-container right-menu-item hover-effect",
                attrs: { trigger: "click" },
              },
              [
                _c("div", { staticClass: "avatar-wrapper" }, [
                  _c("img", {
                    staticClass: "user-avatar",
                    attrs: { src: require("@/assets/js_img/userinfo.png") },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/" } },
                      [
                        _c("el-dropdown-item", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("navbar.dashboard")) +
                              "\n              "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-dropdown-item", [
                      _c(
                        "span",
                        {
                          staticStyle: { display: "block" },
                          on: { click: _vm.logout },
                        },
                        [_vm._v(_vm._s(_vm.$t("navbar.logOut")))]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "js-header" }, [
      _c(
        "div",
        [
          _c("hamburger", {
            staticClass: "hamburger-container",
            style: _vm.isPower
              ? { fill: "currentColor", color: "#97a8be", marginTop: "7px" }
              : { marginTop: "7px" },
            attrs: {
              id: "hamburger-container",
              "is-active": _vm.sidebar.opened,
            },
            on: { toggleClick: _vm.toggleSideBar },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "left-menu" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          [
            _c(
              "div",
              { staticClass: "right-menu-item" },
              [
                _c(
                  "el-select",
                  {
                    class: _vm.isPower ? "actives" : "",
                    staticStyle: { width: "290px" },
                    attrs: { value: _vm.deptId },
                    on: { change: _vm.deptChange },
                  },
                  _vm._l(_vm.deptList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { value: item.id, label: item.orgName },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tianqi" }, [
      _c("span", [_vm._v("晴")]),
      _vm._v(" "),
      _c("span", [_vm._v("12℃")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }