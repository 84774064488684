import request from '@/utils/request';
export function select(data) {
  return request({
    url: '/sql/select',
    method: 'post',
    data: data
  });
}
export function update(data) {
  return request({
    url: '/sql/update',
    method: 'post',
    data: data
  });
}
export function insert(data) {
  return request({
    url: '/sql/insert',
    method: 'post',
    data: data
  });
}
export function remove(data) {
  return request({
    url: '/sql/delete',
    method: 'post',
    data: data
  });
}
export function selectSchemata(data) {
  return request({
    url: '/sql/selectSchemata',
    method: 'post',
    data: data
  });
}
export function selectColumnByTable(data) {
  return request({
    url: '/sql/selectColumnByTable',
    method: 'post',
    data: data
  });
}
export function getLogFileApi() {
  return request({
    url: '/config/base/getLogFiles',
    method: 'get'
  });
}