import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.split";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RightPanel from '@/components/RightPanel';
import { AppMain, Navbar, Settings, Sidebar, TagsView } from "./components";
import JsHeader from "./components/JsHeader.vue";
import EnergyHeader from "./components/EnergyHeader.vue";
import EnergyAppMain from "./components/EnergyAppMain";
import JsAppMain from "./components/JsAppMain";
import JsSidebar from "./components/Sidebar/jsSidebar";
import NySidebar from "./components/Sidebar/nySidebar";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from 'vuex';
import { checkOS } from '@/utils/system';
// // 引入语言播报
import Speech from 'speak-tts';
export default {
  name: 'Layout',
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    JsHeader: JsHeader,
    JsAppMain: JsAppMain,
    JsSidebar: JsSidebar,
    RightPanel: RightPanel,
    Settings: Settings,
    Sidebar: Sidebar,
    TagsView: TagsView,
    EnergyHeader: EnergyHeader,
    EnergyAppMain: EnergyAppMain,
    NySidebar: NySidebar
  },
  mixins: [ResizeMixin],
  data: function data() {
    return {
      innerWidth: window.innerWidth,
      // 重试次数
      reIndex: 0,
      routerPath: '',
      routerList: ['energyManagement', 'management', 'energyAlarm', 'consumptionanalysis', 'buildingEnergy', 'diagnosisEnergySaving', 'energyMonitoring', 'energy-sources'],
      speech: null
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    },
    isFull: function isFull(state) {
      return state.settings.isFull;
    },
    isPower: function isPower(state) {
      return state.settings.isPower;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation
      };
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  created: function created() {
    this.speechInit();
    console.log(this.isFull, 'isFullisFullisFull');
  },
  mounted: function mounted() {
    var _this = this;
    console.log('0', this.$route.fullPath.split('/')[1], '1', this.$route);
    this.$eventHub.$on('ssdm_warn', function (notify) {
      if (notify) {
        console.log('this.speech');
        if (_this.routerList.includes(_this.$route.fullPath.split('/')[1]) && _this.$route.fullPath.split('/')[1] !== 'energy-sources') {
          console.log('开始');
          _this.$notify(notify);
          _this.play(notify);
        } else {
          console.log('取消');
          // this.speech.pause()
          _this.speech.cancel();
        }
      }
    });
  },
  destroyed: function destroyed() {
    this.speech.cancel();
  },
  methods: {
    speechInit: function speechInit() {
      this.speech = new Speech();
      this.speech.setLanguage('zh-CN');
      this.speech.init().then(function () {
        console.log('语音播报初始化完成...');
      });
    },
    play: function play(notify) {
      this.speech.speak({
        text: notify.message,
        listeners: {
          // 开始播放
          onstart: function onstart() {
            console.log('Start utterance');
          },
          // 判断播放是否完毕
          onend: function onend() {
            console.log('End utterance');
          },
          // 恢复播放
          onresume: function onresume() {
            console.log('Resume utterance');
          }
        }
      }).then(function () {
        console.log('读取成功');
      });
    },
    checkOS: checkOS,
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};