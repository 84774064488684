import request from '@/utils/request';
import { checkOS } from '@/utils/system';
export function getApplicationListApi(data) {
  return request({
    url: '/base/application/getApplicationByPage',
    method: 'post',
    data: data
  });
}
export function addApplicationApi(data) {
  return request({
    url: '/base/application/addApplication',
    method: 'post',
    data: data
  });
}
export function deleteApplicationApi(params) {
  return request({
    url: '/base/application/deleteApplication',
    method: 'post',
    params: params
  });
}
export function updateApplicationApi(data) {
  return request({
    url: '/base/application/updateApplication',
    method: 'post',
    data: data
  });
}
export function updateApp2menusApi(data) {
  return request({
    url: '/base/application/updateApp2menus',
    method: 'post',
    data: data
  });
}
export function getApp2menusApi(params) {
  return request({
    url: '/base/application/getApp2menus',
    method: 'get',
    params: params
  });
}
export function getMenusIdByAppIdApi(params) {
  return request({
    url: '/base/application/getMenusIdByAppId',
    method: 'get',
    params: params
  });
}
export function getUserApplicationApi() {
  return request({
    url: '/base/application/getUserApplication',
    method: 'get',
    params: {
      clientType: checkOS() === 'pc' ? '1' : '2'
    }
  });
}