var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: _vm.routerList.includes(_vm.$route.fullPath)
        ? "background-color: #000;"
        : "",
      attrs: { id: "app" },
    },
    [
      _c(
        "keep-alive",
        [
          _vm.$route.meta.noCache
            ? _c("router-view", { key: _vm.$route.fullPath })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.$route.meta.noCache ? _c("router-view") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }