var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-cascader", {
    ref: "deviceId",
    attrs: {
      options: _vm.optionList,
      filterable: _vm.filterable,
      "show-all-levels": _vm.showAllLevels,
      props: {
        value: "sisdDecviceId",
        label: "sisdDecviceName",
        children: "children",
        multiple: true,
        checkStrictly: true,
        emitPath: false,
      },
      clearable: "",
      "popper-class": "z-cascader",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var node = ref.node
          return [
            _c(
              "span",
              {
                staticClass: "z-cascader-label",
                attrs: { id: node.value },
                on: {
                  dblclick: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleMoreChoice(node)
                  },
                },
              },
              [_vm._v(_vm._s(node.label))]
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.newValue,
      callback: function ($$v) {
        _vm.newValue = $$v
      },
      expression: "newValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }