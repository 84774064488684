import request from '@/utils/request';
import { checkOS } from '@/utils/system';
export function getMenusTreeApi() {
  return request({
    url: '/base/menus/getMenusTree',
    method: 'get'
  });
}
export function addMenusApi(data) {
  return request({
    url: '/base/menus/addMenus',
    method: 'post',
    data: data
  });
}
export function deleteMenusApi(params) {
  return request({
    url: '/base/menus/deleteMenus',
    method: 'post',
    params: params
  });
}
export function updateMenusApi(data) {
  return request({
    url: '/base/menus/updateMenus',
    method: 'post',
    data: data
  });
}
export function getMenusAuthTreeApi() {
  return request({
    url: '/base/menus/getMenusAuthTree',
    method: 'get',
    params: {
      clientType: checkOS() === 'pc' ? '1' : '2'
    }
  });
}
export function getMenusByApplicationApi(params) {
  if (checkOS() === 'pc') params.clientType = '1';else params.clientType = '2';
  return request({
    url: '/base/menus/getMenusByApplication',
    method: 'get',
    params: params
  });
}
export function getAppMenusByMenusIdApi(params) {
  return request({
    url: '/base/menus/getAppMenusByMenusId',
    method: 'get',
    params: params
  });
}