import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MyCascader',
  props: {
    optionList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    filterable: {
      type: Boolean,
      default: true
    },
    showAllLevels: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      newValue: []
    };
  },
  watch: {
    newValue: {
      immediate: true,
      deep: true,
      handler: function handler(newVal, oldVal) {
        this.$emit('handelNewValue', newVal);
      }
    }
  },
  methods: {
    handleMoreChoice: function handleMoreChoice(node) {
      var list = this.$refs.deviceId.getCheckedNodes();
      var newList = [];
      list.forEach(function (item) {
        newList.push(item.data.sisdDecviceId);
      });
      this.newValue = newList;
      var nodeList = getNodeChildren(node);
      // const value = JSON.parse(JSON.stringify(this.formDalog.deviceIdList))
      // if (node.checked) {
      // const cancel = []
      // nodeList.forEach((item) => {
      //   for (let index = 1; index < value.length; index++) {
      //     if (String(item.path) === String(value[index])) {
      //       cancel.push(item.value)
      //       value.splice(index, 1)
      //       break
      //     }
      //   }
      // })
      // cancel.forEach((id) => {
      //   console.log(id, '?ididiididididddddddd')
      //   document.getElementById(id).parentElement.previousElementSibling.click()
      // })
      // } else {
      // value.forEach((item) => {
      //   for (let index = 0; index < nodeList.length; index++) {
      //     // console.log(value, node, nodeList, '!!!!!!!!!!!!!!!!!!!')
      //     console.log(String(nodeList[0].path), '??????????????')
      //     console.log(String(item), '///////////////')
      //     if (String(item) === String(nodeList[index].path)) {
      //       nodeList.splice(index, 1)
      //     }

      // if (String(item) === String(nodeList[index].path)) {
      //   console.log(index)
      //   nodeList.splice(index, 1)
      //   break
      // }
      //   }
      // })
      // console.log(nodeList, '????????????????')
      nodeList.forEach(function (item) {
        if (nodeList[0].checked === item.checked && document.getElementById(item.value) !== null) {
          // if () {
          // console.log(node, document.getElementById(value).parentElement, '??@@@@@@@@@@@@@?')
          document.getElementById(item.value).parentElement.previousElementSibling.click();
          // const list = this.$refs.deviceId.getCheckedNodes()
          console.log(node, nodeList[0].checked, item, item.value, item.checked, '111111111111111111111111111111111');
          // }
        }
      });
      // }
      function getNodeChildren(data) {
        var children = [];
        _getNodeChildren(data);
        // console.log(children, '///////')
        return children;
        function _getNodeChildren(_data) {
          children.push({
            path: _data.path,
            value: _data.value,
            checked: _data.checked
          });
          if (_data.children.length) {
            for (var index = 0; index < _data.children.length; index++) {
              _getNodeChildren(_data.children[index]);
            }
          }
        }
      }
    }
  }
};